// @ts-nocheck
import loadable from "@loadable/component";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { useContext } from "react";
import {
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Loading from "./Components/Loading";
import Navigation, { drawerWidth } from "./Components/NavigationDrawer";
import CreateWorkspaceDialog from "./Components/CreateWorkspaceDialog";
import TopBar from "./Components/TopBar";
import AdminSettings from "./Containers/AdminSettings";
import BlogSettings from "./Containers/BlogSettings";
import EmailSettings from "./Containers/EmailSettings";
import IntegrationSettings from "./Containers/IntegrationSettings";
import Posts from "./Containers/Posts";
import StyledDialog from "./design/components/StyledDialog";
import { ReactComponent as StatsIcon } from "./Images/icon24/bar_chart.svg";
import { ReactComponent as DocumentIcon } from "./Images/icon24/document.svg";
import { ReactComponent as PlanningIcon } from "./Images/icon24/ic24-more.svg";
import { ReactComponent as MailIcon } from "./Images/icon24/mail.svg";
import { ReactComponent as MessagesIcon } from "./Images/icon24/messages.svg";
import { ReactComponent as OverviewIcon } from "./Images/icon24/overview.svg";
import { ReactComponent as SettingsIcon } from "./Images/icon24/settings.svg";
import PageAccessContext from "./Utils/PageAccessContext";
import API from "./Services/Api";
import CustomRoute from "./Components/CustomRoute";
import AISettings from "./Containers/AISettings";
import { ErrorNotificationProps } from "./Components/ErrorNotificationDialog";
import { FACEBOOK_ALLOWED_DOMAINS, modulesAccess } from "./Utils/Types";
import SocialSellingSettings from "./Containers/SocialSellingSettings";
import TemplateSettings from "./Containers/TemplateSettings";
import KnowledgeBaseSettings from "./Containers/KnowledgeBaseSettings";
var pathToRegexp = require("path-to-regexp");

const Playground = loadable(() => import("./Containers/Playground"), {
  fallback: <Loading />
});

const PreambleAndFooter = loadable(
  () => import("./Containers/PreambleAndFooter"),
  {
    fallback: <Loading />
  }
);

const PreambleAndFooterTemplate = loadable(
  () => import("./Containers/PreambleAndFooterTemplate"),
  {
    fallback: <Loading />
  }
);

const Templates = loadable(() => import("./Containers/Templates"), {
  fallback: <Loading />
});

const Template = loadable(() => import("./Containers/Template"), {
  fallback: <Loading />
});

const Post = loadable(() => import("./Containers/Post"), {
  fallback: <Loading />
});

const Review = loadable(() => import("./Containers/Review"), {
  fallback: <Loading />
});

const PostEmailStats = loadable(() => import("./Containers/PostEmailStats"), {
  fallback: <Loading />
});

const PostWebStats = loadable(() => import("./Containers/PostWebStats"), {
  fallback: <Loading />
});

const Conversations = loadable(() => import("./Containers/Conversations"), {
  fallback: <Loading />
});

const SalesAssistant = loadable(() => import("./Containers/SalesAssistant"), {
  fallback: <Loading />
});

const SalesAssistantLiveTranscript = loadable(
  () => import("./Containers/SalesAssistantLiveTranscript"),
  {
    fallback: <Loading />
  }
);

const KeywordResearch = loadable(() => import("./Containers/KeywordResearch"), {
  fallback: <Loading />
});

const Overview = loadable(() => import("./Containers/Overview"), {
  fallback: <Loading />
});

const TopicBaord = loadable(() => import("./Containers/Ideas"), {
  fallback: <Loading />
});

const ContentMapEditor = loadable(
  () => import("./Containers/ContentMapEditor"),
  {
    fallback: <Loading />
  }
);

const IdeaGenerator = loadable(() => import("./Containers/IdeaGenerator"), {
  fallback: <Loading />
});

const ProgrammaticTopics = loadable(
  () => import("./Containers/ProgrammaticTopics"),
  {
    fallback: <Loading />
  }
);

const IdeaGenerationFromKeyword = loadable(
  () => import("./Containers/IdeaGenerationFromKeyword"),
  {
    fallback: <Loading />
  }
);

const Calendar = loadable(() => import("./Containers/CalendarView"), {
  fallback: <Loading />
});

const Projects = loadable(() => import("./Containers/Projects"), {
  fallback: <Loading />
});

const PublicationStats = loadable(
  () => import("./Containers/PublicationStats"),
  {
    fallback: <Loading />
  }
);

const EmailStats = loadable(() => import("./Containers/EmailStats"), {
  fallback: <Loading />
});

const LinkedInStats = loadable(() => import("./Containers/LinkedInStats"), {
  fallback: <Loading />
});

const ThirdPartyLeads = loadable(() => import("./Containers/ThirdPartyLeads"), {
  fallback: <Loading />
});

const EngagedAccounts = loadable(() => import("./Containers/EngagedAccounts"), {
  fallback: <Loading />
});

const AllEngagedAccounts = loadable(
  () => import("./Containers/AllEngagedAccounts"),
  {
    fallback: <Loading />
  }
);

const Followups = loadable(() => import("./Containers/Followups"), {
  fallback: <Loading />
});

const OutputStats = loadable(() => import("./Containers/OutputStats"), {
  fallback: <Loading />
});

const QuotePreview = loadable(() => import("./Containers/QuotePreview"), {
  fallback: <Loading />
});

const GoogleStats = loadable(() => import("./Containers/GoogleStats"), {
  fallback: <Loading />
});

const RevenueStats = loadable(() => import("./Containers/RevenueStats"), {
  fallback: <Loading />
});

const GongAnalysis = loadable(() => import("./Containers/GongAnalysis"), {
  fallback: <Loading />
});

const InstagramShare = loadable(() => import("./Containers/InstagramShare"), {
  fallback: <Loading />
});

const TwitterShare = loadable(() => import("./Containers/TwitterShare"), {
  fallback: <Loading />
});

const FacebookShare = loadable(() => import("./Containers/FacebookShare"), {
  fallback: <Loading />
});

const RedditShare = loadable(() => import("./Containers/RedditShare"), {
  fallback: <Loading />
});

const LinkedInShare = loadable(() => import("./Containers/LinkedInShare"), {
  fallback: <Loading />
});

const SearchSubscriber = loadable(
  () => import("./Components/SearchSubscriber"),
  {
    fallback: <Loading />
  }
);

const Subscribers = loadable(() => import("./Containers/Subscribers"), {
  fallback: <Loading />
});

const SubscriberView = loadable(() => import("./Containers/SubscriberView"), {
  fallback: <Loading />
});

const Lists = loadable(() => import("./Containers/Lists"), {
  fallback: <Loading />
});

const List = loadable(() => import("./Containers/List"), {
  fallback: <Loading />
});

const Campaigns = loadable(() => import("./Containers/Campaigns"), {
  fallback: <Loading />
});

const CampaignStats = loadable(() => import("./Containers/CampaignStats"), {
  fallback: <Loading />
});

const CampaignReview = loadable(
  () => import("./Containers/EmailCampaignReview"),
  {
    fallback: <Loading />
  }
);

const Campaign = loadable(() => import("./Containers/Campaign"), {
  fallback: <Loading />
});

const Sequences = loadable(() => import("./Containers/Sequences"), {
  fallback: <Loading />
});

const AddEditSequence = loadable(() => import("./Containers/AddEditSequence"), {
  fallback: <Loading />
});

const CampaignTemplates = loadable(
  () => import("./Containers/CampaignTemplates"),
  {
    fallback: <Loading />
  }
);

const CampaignTemplate = loadable(
  () => import("./Containers/CampaignTemplate"),
  {
    fallback: <Loading />
  }
);

const ToggleNotifications = React.lazy(
  () => import("./Containers/ToggleNotifications")
);

const ErrorNotificationDialog = React.lazy(
  () => import("./Components/ErrorNotificationDialog")
);

const InternalLinks = loadable(() => import("./Containers/InternalLinks"), {
  fallback: <Loading />
});

const LinksHealth = loadable(() => import("./Containers/LinksHealth"), {
  fallback: <Loading />
});

const FindAndReplace = loadable(() => import("./Containers/FindAndReplace"), {
  fallback: <Loading />
});

const PagesToRefresh = loadable(() => import("./Containers/PagesToRefresh"), {
  fallback: <Loading />
});

const SocialDrafts = React.lazy(() => import("./Containers/SocialDrafts"));
const WriterProjects = React.lazy(() => import("./Containers/WriterProjects"));
const Website = React.lazy(() => import("./Containers/Website"));
const KeywordMonitoring = React.lazy(
  () => import("./Containers/KeywordMonitoring")
);

const businessOptions = [
  {
    name: "Overview",
    url: "/overview",
    icon: <OverviewIcon />
  },
  {
    name: "Website",
    icon: <DocumentIcon />,
    hide: (publication) =>
      publication?.disabledModules?.includes(modulesAccess.SEO),
    children: [
      {
        name: "Posts",
        url: "/posts"
      },
      {
        name: "Templates",
        url: "/templates"
      },
      {
        name: "Programmatic SEO",
        url: "/programmatic-seo"
      },
      {
        name: "Internal Links",
        url: "/internal-links"
      },
      {
        name: "Link Health",
        url: "/links"
      },
      {
        name: "Find and Replace",
        url: "/find-and-replace"
      },
      {
        name: "Pages to Refresh",
        url: "/pages-to-refresh"
      }
    ]
  },
  {
    name: "Planning",
    icon: <PlanningIcon />,
    hide: (publication) =>
      publication?.disabledModules?.includes(modulesAccess.CONTENT_OPERATIONS),
    children: [
      {
        name: "Ideas",
        url: "/topics"
      },
      {
        name: "Projects",
        url: "/projects"
      },
      {
        name: "Idea Generator",
        url: "/idea-generator"
      },
      {
        name: "Keyword Research",
        url: "/keywordresearch"
      },
      {
        name: "Content Calendar",
        url: "/calendar"
      }
    ]
  },
  {
    name: "Email",
    icon: <MailIcon />,
    hide: (publication) => !publication?.sendBlogNewsletter,
    children: [
      {
        name: "Campaigns",
        url: "/campaigns"
      },
      {
        name: "Templates",
        url: "/campaign-templates"
      },
      {
        name: "Sequences",
        url: "/sequences"
      },
      {
        name: "Subscribers",
        url: "/subscribers"
      },
      {
        name: "Lists",
        url: "/lists"
      }
    ]
  },
  {
    name: "Social",
    icon: <MessagesIcon />,
    hide: (publication) =>
      publication?.disabledModules?.includes(modulesAccess.SOCIAL),
    children: [
      {
        name: "Twitter",
        url: "/twitter/drafts"
      },
      {
        name: "LinkedIn",
        url: "/linkedin/drafts"
      },
      {
        name: "Facebook",
        url: "/facebook/drafts",
        hide: (publication) => {
          return !FACEBOOK_ALLOWED_DOMAINS.includes(publication?.domain);
        }
      },
      {
        name: "Instagram",
        url: "/instagram/drafts",
        hide: (publication) => {
          return !FACEBOOK_ALLOWED_DOMAINS.includes(publication?.domain);
        }
      },
      {
        name: "Reddit",
        url: "/reddit/drafts"
      }
    ]
  },
  {
    name: "Stats",
    icon: <StatsIcon />,
    children: [
      {
        name: "Google",
        url: "/googlestats"
      },
      {
        name: "Email",
        url: "/emailstats",
        hide: (publication) => !publication?.sendBlogNewsletter
      },
      {
        name: "Blog",
        url: "/analytics",
        hide: () => true // Hide Blog Stats
      },
      {
        name: "LinkedIn",
        url: "/linkedinstats",
        hide: (publication) =>
          publication?.disabledModules?.includes(modulesAccess.SOCIAL)
      },
      {
        name: "3rd Party Leads",
        url: "/3rd-party-leads",
        hide: (publication) =>
          publication?.disabledModules?.includes(modulesAccess.SOCIAL)
      },
      {
        name: "Accounts",
        url: "/allAccounts"
      },
      {
        name: "Followups",
        url: "/followups"
      },
      {
        name: "Revenue",
        url: "/stats/revenue"
      },
      {
        name: "Output",
        url: "/stats/output"
      },
      {
        name: "Keyword Monitoring",
        url: "/keyword-monitoring"
      }
    ]
  },
  {
    name: "Settings",
    icon: <SettingsIcon />,
    children: [
      {
        name: "Admin",
        url: "/adminsettings"
      },
      {
        name: "Blog",
        url: "/blogsettings"
      },
      {
        name: "Website",
        url: "/website"
      },
      {
        name: "Email",
        url: "/emailsettings",
        hide: (publication) => !publication?.sendBlogNewsletter
      },
      {
        name: "Knowledge Base",
        url: "/knowledge-base-settings"
      },
      {
        name: "AI",
        url: "/aisettings"
      },
      {
        name: "Social Selling",
        url: "/social-selling"
      },
      {
        name: "Templates",
        url: "/template-settings"
      },
      {
        name: "Integrations",
        url: "/integrationsettings"
      }
    ]
  }
];

const agencyOptions = [
  {
    name: "Overview",
    url: "/writer-projects",

    icon: <OverviewIcon />
  }
];

const api = API.create();
const ERROR_NOTIFICATIONS_BULK_DIALOG_LIMIT = 3;

export default function Main() {
  let { id } = useParams();
  let { path } = useRouteMatch();
  let { pathname } = useLocation();
  let context = useContext(PageAccessContext);
  let history = useHistory();
  const [openPublicationDialog, setOpenPublicationDialog] =
    React.useState(false);
  const [agencyView, setAgencyView] = React.useState(
    pathname.includes("agency")
  );
  // Show Ask AI Assistant Panel in Social Screens
  const [notifyPaymentFailed, setNotifyPaymentFailed] = React.useState(false);
  const [errorNotifications, setErrorNotifications] = React.useState<
    ErrorNotificationProps[]
  >([]);

  // hide top bar in posts editor, review, preamble, templates, and campaigns
  let pathToHideTopBar = [
    pathToRegexp(`/:id/posts/:id`),
    pathToRegexp(`/:id/posts/:id/preamble`),
    pathToRegexp(`/:id/posts/:id/footer`),
    pathToRegexp(`/:id/posts/:id/review`),
    pathToRegexp(`/:id/templates/:id`),
    pathToRegexp(`/:id/templates/:id/footer`),
    pathToRegexp(`/:id/templates/:id/review`),
    pathToRegexp(`/:id/campaigns/:id`),
    pathToRegexp(`/:id/campaigns/:id/review`),
    pathToRegexp(`/:id/topics/:id/contentmap`),
    pathToRegexp(`/:id/campaign-templates/:id`),
    pathToRegexp(`/:id/notifications/:category`),
    pathToRegexp(`/:id/blog-generator`)
  ];

  let pathToHideNavBar: any = [
    // pathToRegexp(`/:id/posts/:id`),
    // pathToRegexp(`/:id/posts/:id/preamble`),
    // pathToRegexp(`/:id/posts/:id/footer`),
    // pathToRegexp(`/:id/posts/:id/review`),
    // pathToRegexp(`/:id/templates/:id`),
    // pathToRegexp(`/:id/templates/:id/footer`),
    // pathToRegexp(`/:id/templates/:id/review`),
    // pathToRegexp(`/:id/campaigns/:id`),
    // pathToRegexp(`/:id/campaigns/:id/review`),
    // pathToRegexp(`/:id/topics/:id/contentmap`),
    // pathToRegexp(`/:id/campaign-templates/:id`),
  ];
  React.useEffect(() => {
    getErrorNotifications();
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (businessOptions[businessOptions.length - 1].name !== "Playground") {
        businessOptions.push({
          name: "Playground",
          url: "/playground",

          icon: <SportsSoccerIcon />
        });
      }
    }
  }, []);

  React.useEffect(() => {
    api.checkIfUserHasSavedPaymentMethod(id, (res: any) => {
      if (res.status === 200 && res.data.notifyPaymentFailed) {
        setNotifyPaymentFailed(true);
      }
    });
    api.updateLastUserLogin(id, (res: any) => {});
  }, [id]);

  const hideTopBar = React.useMemo(() => {
    let hide = false;
    for (let paths of pathToHideTopBar) {
      if (paths.exec(pathname)) {
        hide = true;
        break;
      }
    }

    return hide;
  }, [pathToHideTopBar, pathname]);

  // Ask AI Panel - get current social tab open (e.g. twitter, facebook etc.)
  const hideNavBar = React.useMemo(() => {
    let hide = false;
    for (let paths of pathToHideNavBar) {
      if (paths.exec(pathname)) {
        hide = true;
        break;
      }
    }

    return hide;
  }, [pathToHideNavBar, pathname]);

  function publicationSaved(publication: any) {
    context.refresh(publication, function () {
      history.push(`/${publication._id}/overview?backpage=publications`);
      setOpenPublicationDialog(false);
      return;
    });
  }

  function updatePaymentFailedNotification() {
    setNotifyPaymentFailed(false);
    api.updatePaymentFailedNotification(id, (res: any) => {});
  }

  const getErrorNotifications = React.useCallback(() => {
    api.getErrorNotifications(id, (res: any) => {
      if (res.status === 200) {
        setErrorNotifications(res.data.notifications || []);
      }
    });
  }, [id]);

  React.useEffect(getErrorNotifications, [id, pathname, getErrorNotifications]);

  function markAsRead(notificationId) {
    api.markErrorNotificationAsRead(id, notificationId, (res: any) => {
      if (res.status === 200) {
        setErrorNotifications(res.data.notifications || []);
      }
    });
  }

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />

      {!hideNavBar && (
        <Navigation
          businessOptions={businessOptions}
          agencyOptions={agencyOptions}
          showPublicationCreate={setOpenPublicationDialog}
          agencyView={agencyView}
        />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          width: `calc(100% - ${drawerWidth}px)`
        }}
      >
        <Box>
          {!hideTopBar && <TopBar />}

          <Switch>
            <CustomRoute
              exact
              path={`/agency/writer-projects`}
              component={WriterProjects}
              isProtected
            />

            <CustomRoute exact path={path} isProtected>
              <h3>Please select one of the options in menu</h3>
            </CustomRoute>

            <CustomRoute
              path={`${path}/templates`}
              exact
              component={Templates}
              isProtected
            />

            <CustomRoute
              path={`${path}/templates/:id`}
              exact
              component={Template}
              isProtected
            />

            <CustomRoute
              path={`${path}/templates/:id/preamble`}
              exact
              component={PreambleAndFooterTemplate}
              isProtected
            />

            <CustomRoute
              path={`${path}/templates/:id/footer`}
              exact
              component={PreambleAndFooterTemplate}
              isProtected
            />

            <CustomRoute
              path={`${path}/keywordresearch`}
              component={KeywordResearch}
              isProtected
            />

            <CustomRoute
              path={`${path}/topics/:topicid?`}
              exact
              component={TopicBaord}
            />

            <CustomRoute
              path={`${path}/projects/:projectid?`}
              exact
              component={Projects}
              isProtected
            />

            <CustomRoute
              path={`${path}/topics/:id/contentmap`}
              exact
              component={ContentMapEditor}
              isProtected
            />

            <CustomRoute
              path={`${path}/overview`}
              component={Overview}
              isProtected
            />

            <CustomRoute
              path={`${path}/quotes/:id`}
              exact
              component={QuotePreview}
              isProtected
            />

            <CustomRoute
              path={`${path}/posts`}
              exact
              component={Posts}
              isProtected
            />

            <CustomRoute path={`${path}/posts/:id`} exact component={Post} />

            <CustomRoute
              path={`${path}/posts/:id/preamble`}
              exact
              component={PreambleAndFooter}
            />

            <CustomRoute
              path={`${path}/posts/:id/footer`}
              exact
              component={PreambleAndFooter}
            />

            <CustomRoute
              path={`${path}/posts/:id/review`}
              exact
              component={Review}
            />

            <CustomRoute
              path={`${path}/posts/:postId/stats`}
              exact
              component={PostWebStats}
              isProtected
            />

            <CustomRoute
              path={`${path}/sales-assistant/`}
              exact
              component={SalesAssistant}
              isProtected
            />

            <CustomRoute
              path={`${path}/sales-assistant/:botId`}
              exact
              component={SalesAssistantLiveTranscript}
              isProtected
            />

            <CustomRoute
              path={`${path}/posts/:id/emailstats`}
              exact
              component={PostEmailStats}
              isProtected
            />

            <CustomRoute
              path={`${path}/linkedin/drafts`}
              exact
              component={SocialDrafts}
              key={Math.random()}
              isProtected
            />
            <CustomRoute
              path={`${path}/linkedin/:linkedinid?`}
              component={LinkedInShare}
              isProtected
            />

            <CustomRoute
              path={`${path}/facebook/drafts`}
              exact
              component={SocialDrafts}
              key={Math.random()}
              isProtected
            />

            <CustomRoute
              path={`${path}/facebook/:facebookid?`}
              component={FacebookShare}
              isProtected
            />

            <CustomRoute
              path={`${path}/reddit/drafts`}
              exact
              component={SocialDrafts}
              key={Math.random()}
              isProtected
            />

            <CustomRoute
              path={`${path}/reddit/:redditid?`}
              component={RedditShare}
              isProtected
            />

            <CustomRoute
              path={`${path}/twitter/drafts`}
              exact
              component={SocialDrafts}
              key={Math.random()}
              isProtected
            />

            <CustomRoute
              path={`${path}/twitter/:tweetid?`}
              component={TwitterShare}
              isProtected
            />

            <CustomRoute
              path={`${path}/instagram/drafts`}
              exact
              component={SocialDrafts}
              key={Math.random()}
              isProtected
            />

            <CustomRoute
              path={`${path}/instagram/:instagramid?`}
              component={InstagramShare}
              isProtected
            />

            <CustomRoute
              path={`${path}/subscribers`}
              exact
              component={Subscribers}
              isProtected
            />

            <CustomRoute
              path={`${path}/subscribers/filter`}
              exact
              component={SearchSubscriber}
              isProtected
            />

            <CustomRoute
              path={`${path}/subscribers/:id`}
              exact
              component={SubscriberView}
              isProtected
            />

            <CustomRoute
              path={`${path}/lists`}
              exact
              component={Lists}
              isProtected
            />

            <CustomRoute
              path={`${path}/list/:listid?`}
              exact
              component={List}
              isProtected
            />

            <CustomRoute
              path={`${path}/campaigns`}
              exact
              component={Campaigns}
              isProtected
            />

            <CustomRoute
              path={`${path}/campaigns/:id`}
              exact
              component={Campaign}
              isProtected
            />

            <CustomRoute
              path={`${path}/campaigns/:id/stats`}
              exact
              component={CampaignStats}
              isProtected
            />

            <CustomRoute
              path={`${path}/campaigns/:id/review`}
              exact
              component={CampaignReview}
              isProtected
            />

            <CustomRoute
              path={`${path}/playground`}
              component={Playground}
              isProtected
            />

            <CustomRoute
              path={`${path}/analytics`}
              component={PublicationStats}
              isProtected
            />

            <CustomRoute
              path={`${path}/emailstats`}
              component={EmailStats}
              isProtected
            />

            <CustomRoute
              path={`${path}/linkedinstats`}
              component={LinkedInStats}
              exact
              isProtected
            />

            <CustomRoute
              path={`${path}/3rd-party-leads`}
              component={ThirdPartyLeads}
              exact
              isProtected
            />

            <CustomRoute
              path={`${path}/accounts/:userId?`}
              component={EngagedAccounts}
              isProtected
              exact
            />

            <CustomRoute
              path={`${path}/allAccounts`}
              component={AllEngagedAccounts}
              isProtected
              exact
            />

            <CustomRoute
              path={`${path}/followups`}
              component={Followups}
              isProtected
              exact
            />

            <CustomRoute
              path={`${path}/stats/output`}
              component={OutputStats}
              isProtected
              exact
            />

            <CustomRoute
              path={`${path}/stats/revenue`}
              component={RevenueStats}
              isProtected
            />

            <CustomRoute
              path={`${path}/googlestats`}
              component={GoogleStats}
              isProtected
            />

            <CustomRoute
              path={`${path}/call-insights`}
              component={GongAnalysis}
              isProtected
            />

            <CustomRoute
              path={`${path}/idea-generator`}
              exact
              component={IdeaGenerator}
              isProtected
            />

            <CustomRoute
              path={`${path}/programmatic-seo`}
              exact
              component={ProgrammaticTopics}
              isProtected
            />

            <CustomRoute
              path={`${path}/idea-generator/ideagenerationfromkeyword`}
              component={IdeaGenerationFromKeyword}
              isProtected
            />

            <CustomRoute path={`${path}/calendar`} component={Calendar} />

            <CustomRoute
              path={`${path}/projects`}
              component={Projects}
              isProtected
            />

            <CustomRoute
              path={`${path}/campaign-templates`}
              exact
              component={CampaignTemplates}
              isProtected
            />

            <CustomRoute
              path={`${path}/campaign-templates/:id`}
              exact
              component={CampaignTemplate}
              isProtected
            />

            <CustomRoute
              path={`${path}/sequences`}
              exact
              component={Sequences}
              isProtected
            />

            <CustomRoute
              path={`${path}/sequences/:sequenceId`}
              exact
              component={AddEditSequence}
              isProtected
            />

            <CustomRoute
              path={`${path}/adminsettings`}
              exact
              component={AdminSettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/website`}
              exact
              component={Website}
              isProtected
            />

            <CustomRoute
              path={`${path}/emailsettings`}
              exact
              component={EmailSettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/blogsettings`}
              exact
              component={BlogSettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/knowledge-base-settings`}
              exact
              component={KnowledgeBaseSettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/aisettings`}
              exact
              component={AISettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/social-selling`}
              exact
              component={SocialSellingSettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/template-settings`}
              exact
              component={TemplateSettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/integrationsettings`}
              exact
              component={IntegrationSettings}
              isProtected
            />

            <CustomRoute
              path={`${path}/notifications/:category`}
              exact
              component={ToggleNotifications}
              isProtected
            />

            <CustomRoute
              path={`${path}/writer-projects`}
              exact
              component={WriterProjects}
              isProtected
            />

            <CustomRoute
              path={`${path}/links`}
              component={LinksHealth}
              isProtected
            />

            <CustomRoute
              path={`${path}/find-and-replace`}
              component={FindAndReplace}
              isProtected
            />

            <CustomRoute
              path={`${path}/internal-links`}
              component={InternalLinks}
              isProtected
            />

            <CustomRoute
              path={`${path}/pages-to-refresh`}
              component={PagesToRefresh}
              isProtected
            />

            <CustomRoute
              path={`${path}/keyword-monitoring`}
              exact
              component={KeywordMonitoring}
              isProtected
            />
          </Switch>
        </Box>

        <StyledDialog
          open={openPublicationDialog}
          title="Create a new workspace"
          body={<CreateWorkspaceDialog publicationSaved={publicationSaved} />}
          closeCallback={() => setOpenPublicationDialog(false)}
          maxWidth="sm"
          fullWidth
        />

        <StyledDialog
          open={notifyPaymentFailed}
          title="Your payment failed"
          body="Your subscription has been downgraded since your payment method failed. Check Contact us at support@letterdrop.com or using the chat widget in the top right for help."
          closeCallback={updatePaymentFailedNotification}
          cancelCallback={updatePaymentFailedNotification}
          maxWidth="sm"
          fullWidth
        />

        {errorNotifications?.map(
          (notification: ErrorNotificationProps, index: number) => {
            // It will show only one notification at a time
            if (index !== 0) {
              return "";
            }

            if (
              errorNotifications.length > ERROR_NOTIFICATIONS_BULK_DIALOG_LIMIT
            ) {
              notification = {
                _id: null,
                text: "It looks like you bulk published some changes and your blog isn't accepting them. Check your email inbox for further error messages. You can address the changes and try publishing again.",
                post: null,
                publication: null
              };
            }

            return (
              <ErrorNotificationDialog
                markAsRead={markAsRead}
                {...notification}
              />
            );
          }
        )}
      </Box>
    </Box>
  );
}
