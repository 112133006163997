import React, { Suspense } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styles from "./styles/TemplateSettingsStyle";
import Loading from "../Components/Loading";
import { Helmet } from "react-helmet";
import {
  Container,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  TableBody,
  Table
} from "@mui/material";
import Page404 from "./Page404";
import Api from "../Services/Api";
import { AxiosResponse } from "axios";
import { withStyles } from "@mui/styles";
import { channelTypes, modulesAccess, Publication } from "../Utils/Types";
import { space } from "../Config/theme";
import { isModuleDisabled } from "../Utils/AccessUtils";
import StyledButton from "../design/components/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PageAccessContext from "../Utils/PageAccessContext";
import colors from "../Themes/Colors";
import { notify } from "../Components/CustomNotifications";
import StyledInput from "../design/components/StyledInput";
import StyledDialog from "../design/components/StyledDialog";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";

const api = Api.create();
const DEFAULT_CHANNEL = channelTypes.LINKEDIN;
const socialChannels = [channelTypes.TWITTER, channelTypes.LINKEDIN];
const outboundChannels = [channelTypes.LINKEDIN, channelTypes.EMAIL];

type TemplateSettingsProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;
type Sample = {
  _id?: string;
  sample: string;
  label: string;
  channel: string;
};
type Template = {
  _id?: string;
  role: string;
  template: string;
  channel: string;
};
type TemplateSettingsState = {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  publication: Publication | null;
  publicationId: string;
  samples: Sample[];
  samplesLoading: boolean;
  sample: string;
  label: string;
  channel: string;
  showAddEditSampleDialog: boolean;
  selectedSampleId: string;
  channelList: string[];
  templates: Template[];
  templatesLoading: boolean;
  role: string;
  template: string;
  templateChannel: string;
  showAddEditTemplateDialog: boolean;
  selectedTemplateId: string;
  outboundChannels: string[];
};

class TemplateSettings extends React.Component<
  TemplateSettingsProps,
  TemplateSettingsState
> {
  static contextType = PageAccessContext;
  constructor(props: TemplateSettingsProps) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      errorMessage: "",
      publicationId: props.match.params.id || "",
      publication: null,
      samples: [],
      samplesLoading: false,
      sample: "",
      label: "",
      channel: DEFAULT_CHANNEL,
      showAddEditSampleDialog: false,
      selectedSampleId: "",
      channelList: socialChannels,
      templates: [],
      templatesLoading: false,
      role: "",
      template: "",
      templateChannel: DEFAULT_CHANNEL,
      showAddEditTemplateDialog: false,
      selectedTemplateId: "",
      outboundChannels: outboundChannels
    };
  }

  componentDidUpdate(
    prevProps: Readonly<TemplateSettingsProps>,
    prevState: Readonly<TemplateSettingsState>
  ): void {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          publicationId: this.props.match.params.id || "",
          publication: null,
          loading: true,
          error: false,
          errorMessage: "",
          samples: [],
          samplesLoading: false,
          sample: "",
          label: "",
          channel: DEFAULT_CHANNEL,
          showAddEditSampleDialog: false,
          selectedSampleId: "",
          channelList: socialChannels,
          templates: [],
          templatesLoading: false,
          role: "",
          template: "",
          templateChannel: DEFAULT_CHANNEL,
          showAddEditTemplateDialog: false,
          selectedTemplateId: "",
          outboundChannels: outboundChannels
        },
        () => {
          this.getPublication();
        }
      );
    }
  }

  componentDidMount(): void {
    this.getPublication();
    this.getSamples();
    this.getTemplates();
  }

  getPublication = async () => {
    let { publicationId } = this.state;

    if (!publicationId) {
      this.setState({
        error: true,
        errorMessage: "Workspace not found"
      });
      return;
    }

    api.getPublicationById(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        this.setState({
          loading: false,
          publication: res.data.publication
        });
      } else {
        this.setState({
          error: true,
          errorMessage: res.status === 400 ? res.data : "Unauthorized access"
        });
      }
    });
  };

  getSamples = () => {
    this.setState({ samplesLoading: true });
    api.getSamples(this.state.publicationId, null, (res: AxiosResponse) => {
      if (res.status === 200) {
        this.setState({ samples: res.data, samplesLoading: false });
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting samples",
          "error"
        );
      }
    });
  };

  getTemplates = () => {
    this.setState({ templatesLoading: true });
    api.getOutboundTemplates(
      this.state.publicationId,
      null,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({ templates: res.data, templatesLoading: false });
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error getting templates",
            "error"
          );
        }
      }
    );
  };

  deleteSample = (sampleId: string) => {
    api.deleteSample(
      this.state.publicationId,
      sampleId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.getSamples();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error deleting sample",
            "error"
          );
        }
      }
    );
  };
  deleteTemplate = (templateId: string) => {
    let { publicationId } = this.state;
    api.deleteOutboundTemplate(
      publicationId,
      templateId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.getTemplates();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error deleting outbound template",
            "error"
          );
        }
      }
    );
  };

  deleteSampleRow = (index: number) => {
    let { samples } = this.state;
    let sampleToDelete = samples[index];
    samples.splice(index, 1);
    this.setState({ samples });

    this.deleteSample(sampleToDelete?._id || "");
  };

  deleteTemplateRow = (index: number) => {
    let { templates } = this.state;
    let templateToDelete = templates[index];
    templates.splice(index, 1);
    this.setState({ templates });

    this.deleteTemplate(templateToDelete?._id || "");
  };

  editSampleRow(index: number) {
    const { samples } = this.state;
    const sample = samples[index];

    this.setState({
      sample: sample.sample,
      label: sample.label,
      channel: sample.channel,
      showAddEditSampleDialog: true,
      selectedSampleId: sample._id || ""
    });
  }

  editTemplateRow(index: number) {
    const { templates } = this.state;
    const template = templates[index];

    this.setState({
      template: template.template,
      role: template.role,
      templateChannel: template.channel,
      showAddEditTemplateDialog: true,
      selectedTemplateId: template._id || ""
    });
  }

  openAddEditSampleDialog = () => {
    this.setState({ showAddEditSampleDialog: true });
  };

  openAddEditTemplateDialog = () => {
    this.setState({ showAddEditTemplateDialog: true });
  };

  closeAddEditSampleDialog = () => {
    this.setState({
      showAddEditSampleDialog: false,
      selectedSampleId: "",
      sample: "",
      label: "",
      channel: DEFAULT_CHANNEL
    });
  };

  closeAddEditTemplateDialog = () => {
    this.setState({
      showAddEditTemplateDialog: false,
      selectedTemplateId: "",
      template: "",
      role: "",
      channel: DEFAULT_CHANNEL
    });
  };

  saveSample = (sample: any) => {
    this.setState({ samplesLoading: true });
    sample.isGenericTemplate = false;
    api.saveSample(this.state.publicationId, sample, (res: AxiosResponse) => {
      this.setState({ samplesLoading: false });
      this.getSamples();
      if (res.status !== 200) {
        notify.show(
          res.status === 400 ? res.data : "Error saving sample",
          "error"
        );
      }
    });
  };

  saveTemplate = (template: any) => {
    let { publicationId } = this.state;
    this.setState({ templatesLoading: true });
    api.saveOutboundTemplate(publicationId, template, (res: AxiosResponse) => {
      this.setState({ templatesLoading: false });
      this.getTemplates();
      if (res.status !== 200) {
        notify.show(
          res.status === 400 ? res.data : "Error saving outbound template",
          "error"
        );
      }
    });
  };

  saveOrUpdateSample = () => {
    const { label, sample, channel, selectedSampleId, samples } = this.state;
    if (!label) {
      notify.show("Please enter label", "error");
      return;
    }
    if (!sample || !channel) {
      notify.show("Please enter sample and channel", "error");
      return;
    }

    if (selectedSampleId) {
      let sampleIndex = samples.findIndex(
        (sample) => sample._id === selectedSampleId
      );
      let editedSample = {
        ...samples[sampleIndex],
        sample: sample?.trim(),
        channel,
        label
      };

      samples[sampleIndex] = editedSample;
      this.setState({ samples });
      this.saveSample(editedSample);
    } else {
      let newSample = {
        sample: sample?.trim(),
        channel,
        label
      };
      if (!samples) {
        this.setState({ samples: [] });
      }

      samples.push(newSample);
      this.setState({ samples });
      this.saveSample(newSample);
    }

    this.setState({
      samples,
      showAddEditSampleDialog: false,
      selectedSampleId: "",
      sample: "",
      label: "",
      channel: DEFAULT_CHANNEL
    });
  };

  saveOrUpdateTemplate = () => {
    const { role, template, templateChannel, selectedTemplateId, templates } =
      this.state;
    if (!role) {
      notify.show("Please enter role", "error");
      return;
    }
    if (!template || !templateChannel) {
      notify.show("Please enter template and channel", "error");
      return;
    }

    if (selectedTemplateId) {
      let templateIndex = templates.findIndex(
        (template) => template._id === selectedTemplateId
      );
      let editedTemplate = {
        ...templates[templateIndex],
        template: template?.trim(),
        channel: templateChannel,
        role
      };

      templates[templateIndex] = editedTemplate;
      this.setState({ templates });
      this.saveTemplate(editedTemplate);
    } else {
      let newTemplate = {
        template: template?.trim(),
        channel: templateChannel,
        role
      };
      if (!templates) {
        this.setState({ templates: [] });
      }

      templates.push(newTemplate);
      this.setState({ templates });
      this.saveTemplate(newTemplate);
    }

    this.setState({
      templates,
      showAddEditTemplateDialog: false,
      selectedTemplateId: "",
      template: "",
      role: "",
      templateChannel: DEFAULT_CHANNEL
    });
  };

  onSampleChange = (e: any) => {
    this.setState({ sample: e.target.value });
  };

  onLabelChange = (e: any) => {
    this.setState({ label: e.target.value });
  };

  onChannelChange = (e: any) => {
    this.setState({ channel: e.target.value });
  };

  onRoleChange = (e: any) => {
    this.setState({ role: e.target.value });
  };

  onTemplateChange = (e: any) => {
    this.setState({ template: e.target.value });
  };

  onTemplateChannelChange = (e: any) => {
    this.setState({ templateChannel: e.target.value });
  };

  render() {
    let {
      loading,
      error,
      errorMessage,
      publication,
      publicationId,
      samplesLoading,
      samples,
      label,
      sample,
      channel,
      showAddEditSampleDialog,
      selectedSampleId,
      channelList,
      templates,
      templatesLoading,
      role,
      template,
      showAddEditTemplateDialog,
      selectedTemplateId,
      templateChannel,
      outboundChannels
    } = this.state;
    let { classes } = this.props;

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <Helmet>
          <title>
            Template Settings {publication && "- " + publication.name} |
            Letterdrop
          </title>
        </Helmet>

        <Container style={{ paddingTop: 24 }}>
          <Typography variant="h600" paragraph>
            Template Settings
          </Typography>

          <div className={classes.innerContainer}>
            <Grid container direction="row" component="div">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {!isModuleDisabled(
                  this.context,
                  publicationId,
                  modulesAccess.SOCIAL
                ) && (
                  <Grid item xs={12} style={{ marginTop: space.LARGE }}>
                    <div style={{ marginTop: space.LARGE }}>
                      <Typography variant="h400" paragraph>
                        LinkedIn Content Templates
                      </Typography>

                      <Typography variant="bodym">
                        Add templates to match your tone and voice
                      </Typography>
                    </div>

                    <Grid container direction="column">
                      {samplesLoading && <Loading />}

                      {!samplesLoading && samples?.length > 0 && (
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Label</TableCell>
                                <TableCell>Template</TableCell>

                                <TableCell>Channel</TableCell>

                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {samples.map((value, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      style={{
                                        width: "20%"
                                      }}
                                    >
                                      <Grid
                                        style={{
                                          lineHeight: "20px",
                                          maxHeight: "40px",
                                          overflow: "hidden"
                                        }}
                                      >
                                        {value.label}
                                      </Grid>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "40%"
                                      }}
                                    >
                                      <Grid
                                        style={{
                                          lineHeight: "20px",
                                          maxHeight: "40px",
                                          overflow: "hidden"
                                        }}
                                      >
                                        {value.sample}
                                      </Grid>
                                    </TableCell>

                                    <TableCell style={{ width: "30%" }}>
                                      <Grid container direction="row">
                                        <Grid item xs={12}>
                                          <Typography variant="bodym">
                                            {" "}
                                            {value.channel}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "10%"
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row"
                                        }}
                                      >
                                        <div>
                                          <Tooltip title="Edit">
                                            <IconButton
                                              onClick={() => {
                                                this.editSampleRow(index);
                                              }}
                                              size="large"
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </div>

                                        <div>
                                          <Tooltip title="Delete">
                                            <IconButton
                                              onClick={() =>
                                                this.deleteSampleRow(index)
                                              }
                                              size="large"
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}

                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.marginTop20}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <StyledButton
                            variant="textprimary"
                            onClick={this.openAddEditSampleDialog}
                            startIcon={<AddCircleIcon />}
                          >
                            Add Template
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </Grid>

                    <hr color={colors.horizontalRule} />
                  </Grid>
                )}
                <Grid item xs={12} style={{ marginTop: space.LARGE }}>
                  <div style={{ marginTop: space.LARGE }}>
                    <Typography variant="h400" paragraph>
                      Outbound Templates
                    </Typography>

                    <Typography variant="bodym">
                      Add templates to use in outbound LinkedIn DMs and Emails
                    </Typography>
                  </div>

                  <Grid container direction="column">
                    {templatesLoading && <Loading />}

                    {!templatesLoading && templates?.length > 0 && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Role</TableCell>
                              <TableCell>Template</TableCell>

                              <TableCell>Channel</TableCell>

                              <TableCell align="right"></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {templates.map((value, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{
                                      width: "20%"
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        lineHeight: "20px",
                                        maxHeight: "40px",
                                        overflow: "hidden"
                                      }}
                                    >
                                      {value.role}
                                    </Grid>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "40%"
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        lineHeight: "20px",
                                        maxHeight: "40px",
                                        overflow: "hidden"
                                      }}
                                    >
                                      {value.template}
                                    </Grid>
                                  </TableCell>

                                  <TableCell style={{ width: "30%" }}>
                                    <Grid container direction="row">
                                      <Grid item xs={12}>
                                        <Typography variant="bodym">
                                          {" "}
                                          {value.channel}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      width: "10%"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row"
                                      }}
                                    >
                                      <div>
                                        <Tooltip title="Edit">
                                          <IconButton
                                            onClick={() => {
                                              this.editTemplateRow(index);
                                            }}
                                            size="large"
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>

                                      <div>
                                        <Tooltip title="Delete">
                                          <IconButton
                                            onClick={() =>
                                              this.deleteTemplateRow(index)
                                            }
                                            size="large"
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.marginTop20}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <StyledButton
                          variant="textprimary"
                          onClick={this.openAddEditTemplateDialog}
                          startIcon={<AddCircleIcon />}
                        >
                          Add Template
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  <hr color={colors.horizontalRule} />
                </Grid>
                {showAddEditTemplateDialog && (
                  <StyledDialog
                    open={showAddEditTemplateDialog}
                    title={
                      selectedTemplateId ? "Update Template" : "Add Template"
                    }
                    body={
                      <div style={{ padding: `0 ${space.LARGE}` }}>
                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Role
                          </Typography>

                          <StyledInput
                            autoFocus
                            type="text"
                            size="large"
                            name="label"
                            fullWidth
                            placeholder="COO"
                            value={role}
                            onChange={this.onRoleChange}
                          />
                        </div>
                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Grid
                            container
                            direction="column"
                            style={{ marginBottom: space.SMALL }}
                          >
                            <Grid item xs={12}>
                              <Typography variant="bodym">Template</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="bodys"
                                style={{
                                  color: colors.fontSecondary
                                }}
                              >
                                {`Supports variables {{first_name}}, {{company}}, {{observation}}`}
                              </Typography>
                            </Grid>
                          </Grid>
                          <StyledInput
                            type="textarea"
                            multiline={true}
                            rows={3}
                            size="large"
                            name="template"
                            fullWidth
                            value={template}
                            onChange={this.onTemplateChange}
                          />
                        </div>

                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Channel
                          </Typography>

                          <StyledSelect
                            name="channel"
                            fullWidth
                            value={templateChannel}
                            size="medium"
                            onChange={this.onTemplateChannelChange}
                          >
                            {Object.values(outboundChannels).map(
                              (channel, index) => {
                                return (
                                  <StyledMenuItem value={channel} key={channel}>
                                    <Grid container direction="row">
                                      <Grid item xs={12}>
                                        <Typography variant="bodym">
                                          {" "}
                                          {channel}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </StyledMenuItem>
                                );
                              }
                            )}
                          </StyledSelect>
                        </div>
                      </div>
                    }
                    successButtonName={selectedTemplateId ? "Update" : "Add"}
                    successCallback={this.saveOrUpdateTemplate}
                    cancelCallback={this.closeAddEditTemplateDialog}
                  />
                )}
                {showAddEditSampleDialog && (
                  <StyledDialog
                    open={showAddEditSampleDialog}
                    title={selectedSampleId ? "Update field" : "Add field"}
                    body={
                      <div style={{ padding: `0 ${space.LARGE}` }}>
                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Label
                          </Typography>

                          <StyledInput
                            autoFocus
                            type="text"
                            size="large"
                            name="label"
                            fullWidth
                            placeholder="Product Launch"
                            value={label}
                            onChange={this.onLabelChange}
                          />
                        </div>
                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Template
                          </Typography>

                          <StyledInput
                            type="textarea"
                            multiline={true}
                            rows={3}
                            size="large"
                            name="sample"
                            fullWidth
                            value={sample}
                            onChange={this.onSampleChange}
                          />
                        </div>

                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Channel
                          </Typography>

                          <StyledSelect
                            name="channel"
                            fullWidth
                            value={channel}
                            size="medium"
                            onChange={this.onChannelChange}
                          >
                            {Object.values(channelList).map(
                              (channel, index) => {
                                return (
                                  <StyledMenuItem value={channel} key={channel}>
                                    <Grid container direction="row">
                                      <Grid item xs={12}>
                                        <Typography variant="bodym">
                                          {" "}
                                          {channel}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </StyledMenuItem>
                                );
                              }
                            )}
                          </StyledSelect>
                        </div>
                      </div>
                    }
                    successButtonName={selectedSampleId ? "Update" : "Add"}
                    successCallback={this.saveOrUpdateSample}
                    cancelCallback={this.closeAddEditSampleDialog}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(TemplateSettings));
