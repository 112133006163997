import { CSSProperties } from "@mui/styles";

const styles = (theme: any): Record<string, CSSProperties> => ({
  container: {
    display: "flex",
    flexDirection: "column" as const
  }
});

export default styles;
