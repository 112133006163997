import { space } from "../../Config/theme";
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  innerContainer: {
    paddingBottom: 80,
    color: colors.fontPrimary,
    paddingTop: space.MEDIUM
  },
  divider: {
    margin: "30px 0px"
  }
});

export default styles;
