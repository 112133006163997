// @ts-nocheck
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { Suspense, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { notify } from "../Components/CustomNotifications";
import Loading from "../Components/Loading";
import { space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import StyledSwitch from "../design/components/StyledSwitch";
import API from "../Services/Api";
import Page404 from "./Page404";
import styles from "./styles/SettingsStyles";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";

import BetaText from "../Components/BetaText";
import colors from "../Themes/Colors";
import {
  accountSegmentTypes,
  channelTypes,
  pathOptionsLabel,
  postStatus,
  SortOrderEnum
} from "../Utils/Types";

import { AxiosResponse } from "axios";
import { useLocation } from "react-router";
import PathSelectionFilter from "../Components/PathSelectionFilter";
import TagsInput from "../Components/TagsInput";
import DemoVideoDialog from "../design/components/DemoVideoDialog";
import StyledCheckbox from "../design/components/StyledCheckbox";
import StyledInputLabel from "../design/components/StyledInputLabel";
import StyledTab from "../design/components/StyledTab";
import StyledUserChip from "../design/components/StyledUserChip";
import PageAccessContext from "../Utils/PageAccessContext";

const STYLE_GUIDE_SAMPLE = `Title: Quick Style Guide for XYZ Company Content Creation

Introduction: This condensed style guide ensures consistency, upholds the brand identity, and maintains quality across XYZ Company's content channels, including website articles, social media posts, and marketing materials.

1. Brand Voice and Tone
   - Voice: Knowledgeable, approachable, and conversational.
   - Tone: Confident and friendly. Use active voice and avoid jargon.

2. Writing Style and Formatting
   - Headings: Use attention-grabbing and descriptive text. Write in title case.
   - Paragraphs: Limit to 3-4 sentences, addressing one main idea.
   - Lists: Use bullet points for simple lists and numbered lists for sequences.
   - Links: Ensure all links are relevant, working, and open in a new tab.

3. Grammar, Punctuation, and Spelling
   - Follow AP style guide rules, with exceptions mentioned below.
   - Use the Oxford comma in lists.
   - Use American spelling and punctuation.

4. Accessibility and Inclusivity
   - Use gender-neutral language and terms.
   - Describe visuals and include alternative text for images.
   - Ensure content is suitable for a broad audience and various cultural backgrounds.

5. Text Formatting
   - Font: Use Arial, size 12 for body text and size 14 or 18 for headings.
   - Bold and italics: Use sparingly and for emphasis only.
   - Colors: Stick to company-approved colors for text and background.

6. Visuals
   - Images: Use high-quality, relevant images with proper permissions.
   - Logos: Use the latest version of the company logo for all materials.
   - Infographics: Maintain brand color palette, fonts, and style.

7. Word and Phrase Guidelines
   - Company name: Always write "XYZ Company" in full when first mentioned. On subsequent mentions, "XYZ" is acceptable.
   - Maintain consistency in terminology and avoid buzzwords or clichés.

8. Fact-checking and Editing
   - Always double-check facts, figures, and sources for accuracy.
   - Edit content for clarity, grammar, punctuation, and overall quality.

9. Approval Process
   - Submit content for approval by the assigned team member or editor, adhering to deadlines.
   `;

const labelTabsValue = {
  SELECT_FROM_BLOG_POSTS: "select_from_blog_posts",
  DEFINE_A_PATH: "define_a_path"
};

const accountSegmentTypeForTabs = {
  [labelTabsValue.SELECT_FROM_BLOG_POSTS]: accountSegmentTypes.POST,
  [labelTabsValue.DEFINE_A_PATH]: accountSegmentTypes.PATH
};

const labelTabs = [
  {
    label: "Select from blog posts",
    value: labelTabsValue.SELECT_FROM_BLOG_POSTS
  },
  {
    label: "Define a path",
    value: labelTabsValue.DEFINE_A_PATH
  }
];

const socialChannels = [channelTypes.TWITTER, channelTypes.LINKEDIN];
const api = API.create();

function AISettings({ classes, match, location }: any) {
  const DEFAULT_CHANNEL = channelTypes.LINKEDIN;

  const DEFAULT_LIMIT = 20;
  const DEFAULT_OFFSET = 0;

  const [loading, setLoading] = React.useState(true);
  const [accountSegmentsLoading, setAccountSegmentsLoading] =
    React.useState(false);
  const [questionsLoading, setQuestionsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [publicationId, setPublicationId] = React.useState(
    match.params.id ? match.params.id : ""
  );
  const [publication, setPublication] = React.useState(null);
  const [generateOutlineAndArticleByAI, setGenerateOutlineAndArticleByAI] =
    React.useState(false);
  const [showAddEditLabelDialog, setShowAddEditLabelDialog] =
    React.useState(false);
  const [selectedAccountSegmentId, setSelectedAccountSegmentId] =
    React.useState(null);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [accountSegmentLabel, setAccountSegmentLabel] = React.useState("");
  const [accountSegmentLabelError, setAccountSegmentLabelError] =
    React.useState("");
  const [contents, setContents] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const [isAllPostSelected, setIsAllPostSelected] = React.useState(false);
  const [accountSegments, setAccountSegments] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);

  const [styleGuideRules, setStyleGuideRules] = React.useState("");
  const [lastSavedStyleGuideRules, setLastSavedStyleGuideRules] =
    React.useState("");

  const [selectedTab, setSelectedTab] = React.useState(
    labelTabsValue.SELECT_FROM_BLOG_POSTS
  );
  const [questionReport, setQuestionReport] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [questionReportEmails, setQuestionReportEmails] = React.useState([]);
  const pageLocation = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState(pageLocation);

  useEffect(() => {
    if (currentLocation.pathname !== pageLocation.pathname) {
      window?.location?.reload?.();
    }
  }, [pageLocation, currentLocation]);

  const toggleOutlineGenerationAI = () => {
    setGenerateOutlineAndArticleByAI(!generateOutlineAndArticleByAI);
    api.toggleOutlineGenerationAI(
      publicationId,
      !generateOutlineAndArticleByAI,
      (res: AxiosResponse) => {
        if (res.status !== 200) {
          setGenerateOutlineAndArticleByAI(generateOutlineAndArticleByAI);
          notify.show(
            res.status === 400
              ? res.data
              : "Oops. We couldn't save your change. Try again.",
            "error"
          );
        }
      }
    );
  };

  const toggleQuestionNotifications = () => {
    setQuestionReport(!questionReport);
    api.toggleQuestionNotifications(
      publicationId,
      !questionReport,
      (res: AxiosResponse) => {
        if (res.status !== 200) {
          setQuestionReport(questionReport);
          notify.show(
            res.status === 400
              ? res.data
              : "Oops. We couldn't save your change. Try again.",
            "error"
          );
        }
      }
    );
  };

  const onTabChange = (e: any, tabName: string) => {
    setSelectedTab(tabName);
  };

  const onFilterChange = (filters: any) => {
    setFilters(filters);
  };


  const saveAccountSegment = (accountSegment: any) => {
    setAccountSegmentsLoading(true);
    api.saveAccountSegment(
      publicationId,
      accountSegment,
      (res: AxiosResponse) => {
        setAccountSegmentsLoading(false);
        getAccountSegments();
        if (res.status === 200) {
          notify.show("Account Segment Label saved successfully", "success");
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Error saving Account Segment Label",
            "error"
          );
        }
      }
    );
  };

  const openAddEditLabelDialog = () => {
    setShowAddEditLabelDialog(true);
  };

  const deleteQuestionRow = (index: number) => {
    let questionToDelete = questions[index];
    let newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions([...newQuestions]);

    deleteQuestion(questionToDelete.question?._id);
  };

  const deleteQuestion = (questionId: string) => {
    api.deleteQuestion(publicationId, questionId, (res: AxiosResponse) => {
      if (res.status === 200) {
        notify.show("Question deleted successfully", "success");
        getQuestions();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error deleting question",
          "error"
        );
      }
    });
  };

  const getCheckedFlag = (options: any) => {
    if (isAllPostSelected) {
      return true;
    }
    return contents?.some((account: any) => account._id === options._id);
  };

  const getStyleGuideRules = () => {
    api.getStyleGuideRules(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        let details = res.data || "";
        setStyleGuideRules(details);
        setLastSavedStyleGuideRules(details);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting company details",
          "error"
        );
      }
    });
  };

  const saveStyleGuideRules = (styleGuideRules: any) => {
    api.saveStyleGuideRules(
      publicationId,
      styleGuideRules,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          getStyleGuideRules();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error saving company details",
            "error"
          );
        }
      }
    );
  };

  const getSaveStyleGuideRulesButton = () => {
    if (lastSavedStyleGuideRules === styleGuideRules) return "";
    return (
      <Grid item className={classes.saveCompanyDetailsButton}>
        <StyledButton onClick={() => saveStyleGuideRules(styleGuideRules)}>
          Save
        </StyledButton>
      </Grid>
    );
  };

  const closeAddEditLabelDialog = () => {
    setShowAddEditLabelDialog(false);
    setSelectedAccountSegmentId(null);
    setContents([]);
    setAccountSegmentLabel("");
    setAccountSegmentLabelError("");
  };


  const saveOrUpdateAccountSegment = () => {
    if (!accountSegmentLabel) {
      notify.show("Please enter label", "error");
      return;
    }
    if (accountSegmentLabelError) {
      notify.show(accountSegmentLabelError, "error");
      return;
    }
    if (
      selectedTab === labelTabsValue.SELECT_FROM_BLOG_POSTS &&
      !contents.length
    ) {
      notify.show("Please select post content", "error");
      return;
    }

    if (selectedTab === labelTabsValue.DEFINE_A_PATH && !filters.length) {
      notify.show("You should have atleast one path", "error");
      return;
    }

    if (selectedAccountSegmentId) {
      let accountSegmentIndex = accountSegments.findIndex(
        (accountSegment) => accountSegment._id === selectedAccountSegmentId
      );
      let editedAccountSegment = {
        ...accountSegments[accountSegmentIndex],
        contents:
          selectedTab === labelTabsValue.SELECT_FROM_BLOG_POSTS
            ? contents.map((content: any) => content._id)
            : [],
        label: accountSegmentLabel,
        type: accountSegmentTypeForTabs[selectedTab],
        filters: selectedTab === labelTabsValue.DEFINE_A_PATH ? filters : []
      };

      accountSegments[accountSegmentIndex] = editedAccountSegment;
      setAccountSegments(accountSegments);
      saveAccountSegment(editedAccountSegment);
    } else {
      let newAccountSegment = {
        contents:
          selectedTab === labelTabsValue.SELECT_FROM_BLOG_POSTS
            ? contents.map((content: any) => content._id)
            : [],
        label: accountSegmentLabel,
        type: accountSegmentTypeForTabs[selectedTab],
        filters: selectedTab === labelTabsValue.DEFINE_A_PATH ? filters : []
      };
      if (!accountSegments) {
        setAccountSegments([newAccountSegment]);
      } else {
        setAccountSegments([...accountSegments, newAccountSegment]);
      }

      saveAccountSegment(newAccountSegment);
    }

    setShowAddEditLabelDialog(false);
    setSelectedAccountSegmentId(null);
    setAccountSegmentLabel("");
    setContents([]);
  };

  const getAccountSegments = () => {
    api.getAccountSegments(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setAccountSegments(res.data);
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Error getting Account Segment Labels",
          "error"
        );
      }
    });
  };

  const getQuestions = () => {
    api.getQuestions(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setQuestions(res.data);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting Questions",
          "error"
        );
      }
    });
  };

  const deleteAccountSegment = (accountSegmentId: string) => {
    api.deleteAccountSegment(
      publicationId,
      accountSegmentId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          notify.show("Account Segment Label deleted successfully", "success");
          getAccountSegments();
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Error deleting Account Segment Label",
            "error"
          );
        }
      }
    );
  };

  const onAccountSegmentLabelChange = (e: any) => {
    //only accept the following chars and validate that the label only consists of: [a-z,0-9,_]
    const regex = /^[a-zA-Z0-9_]*$/;
    setAccountSegmentLabelError("");
    if (e.target.value.length > 0 && !regex.test(e.target.value)) {
      setAccountSegmentLabelError(
        "Label Name can only contain letters, numbers and underscores"
      );
    }
    setAccountSegmentLabel(e.target.value);
  };

  const getChannelDetails = () => {
    api.getChannelDetails(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setPublication(res.data.publication);
        setQuestionReport(
          res.data.publication?.emailNotifications?.salesCallQuestionReport
        );
        setQuestionReportEmails(res.data.publication?.questionReportEmails);
        setGenerateOutlineAndArticleByAI(
          res.data.publication?.generateOutlineAndArticleByAI
        );
        setLoading(false);
      } else {
        setError(true);
        setErrorMessage(
          res.status === 400 ? res.data : "Oops, Something went wrong."
        );
      }
    });
  };

  const editAccountSegmentRow = (index: number) => {
    let accountSegment = accountSegments[index];

    setContents(accountSegment.contents);
    setAccountSegmentLabel(accountSegment.label);
    setFilters(accountSegment.filters);
    setSelectedTab(
      accountSegment.type === accountSegmentTypes.POST
        ? labelTabsValue.SELECT_FROM_BLOG_POSTS
        : labelTabsValue.DEFINE_A_PATH
    );
    setShowAddEditLabelDialog(true);

    setSelectedAccountSegmentId(accountSegment._id);
  };

  const deleteAccountSegmentRow = (index: number) => {
    let accountSegmentToDelete = accountSegments[index];
    accountSegments.splice(index, 1);
    setAccountSegments([...accountSegments]);

    deleteAccountSegment(accountSegmentToDelete._id);
  };

  const getPosts = () => {
    api.getPosts(
      postStatus.PUBLISHED,
      publicationId,
      DEFAULT_LIMIT,
      DEFAULT_OFFSET,
      searchKeyword, //searchKeyword
      [], //destinationList
      [], //compositeSEORating
      SortOrderEnum.ASC,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          setPosts(res.data.posts.docs);
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error getting posts",
            "error"
          );
        }
      }
    );
  };

  const getPostDebounce = _.debounce(getPosts, 1000);

  const saveQuestionReportEmails = (emails: string[]) => {
    api.saveQuestionReportEmails(
      publicationId,
      emails,
      (res: AxiosResponse) => {
        if (res.status !== 200) {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops. We couldn't save your change. Try again.",
            "error"
          );
        }
      }
    );
  };

  useEffect(() => {
    getChannelDetails();
    getPosts();
    getAccountSegments();
    getQuestions();
    getStyleGuideRules();
  }, []);

  useEffect(() => {
    if (searchKeyword.length > 2) {
      getPostDebounce();
    }
  }, [searchKeyword]);

  if (error) {
    return (
      <Suspense fallback={<div />}>
        <Page404 errorMessage={errorMessage} />
      </Suspense>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>
          AI Settings {publication && "- " + publication.name} | Letterdrop
        </title>
      </Helmet>

      <Container>
        <Typography variant="h600" paragraph style={{ paddingTop: space.L }}>
          AI Settings
        </Typography>

        <div className={classes.innerContainer}>
          <Grid container direction="row" justify="center">
            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400" paragraph>
                  AI Outline and Draft Generation
                </Typography>
              </div>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                marginTop={space.SMALL}
              >
                <Grid item>
                  <StyledSwitch
                    checked={generateOutlineAndArticleByAI}
                    name="aiOutlineGeneration"
                    onChange={toggleOutlineGenerationAI}
                  />
                </Grid>

                <Grid item>
                  <Typography variant="bodym">
                    Automatically generate an outline and draft from briefs when
                    assigned
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400">
                  Company Style Guide <BetaText />
                </Typography>

                <Grid container direction="column" style={{ paddingTop: 10 }}>
                  <StyledInput
                    multiline
                    rows={5}
                    size="medium"
                    placeholder={STYLE_GUIDE_SAMPLE}
                    name="styleGuideRules"
                    className={classes.companyDetails}
                    value={styleGuideRules}
                    onChange={(event: any) => {
                      setStyleGuideRules(event.target.value);
                    }}
                  />
                  {getSaveStyleGuideRulesButton()}
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400" paragraph>
                  Account Segmentation
                </Typography>

                <Typography variant="bodym" className={classes.cardSubtitle}>
                  We'll use these to label accounts based on what content they
                  consume. Example: We have a feature around "internal linking".
                  We can categorize accounts as interesting in "internal
                  linking" if they engage with content around the topic.
                </Typography>
              </div>

              <Grid container direction="column">
                {accountSegmentsLoading && <Loading />}

                {!accountSegmentsLoading && accountSegments?.length > 0 && (
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Label</TableCell>
                          <TableCell>Pages</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {accountSegments.map((value, index) => {
                          return (
                            <TableRow width="100%" key={index}>
                              <TableCell
                                style={{
                                  width: "30%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.label}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "60%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.type === accountSegmentTypes.POST
                                    ? value.contents.map(
                                        (content: any, index: number) => {
                                          return (
                                            <>
                                              {index === 0 ? "" : ", "}
                                              <a
                                                href={`posts/${content._id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {content.title}
                                              </a>
                                            </>
                                          );
                                        }
                                      )
                                    : value.filters.map(
                                        (filter: any, index: number) => {
                                          return (
                                            <>
                                              {index === 0 ? "" : ", "}
                                              {`${
                                                pathOptionsLabel[filter.option]
                                              }: ${filter.path}`}
                                            </>
                                          );
                                        }
                                      )}
                                </Grid>
                              </TableCell>

                              <TableCell
                                style={{
                                  width: "10%"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <div className={classes.actionButton}>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        onClick={() => {
                                          editAccountSegmentRow(index);
                                        }}
                                        size="large"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>

                                  <div className={classes.actionButton}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() =>
                                          deleteAccountSegmentRow(index)
                                        }
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <Grid
                  item
                  container
                  direction="row"
                  className={clsx(classes.marginTop20, classes.justifyContent)}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledButton
                      variant="textprimary"
                      className={classes.paddingLeft10}
                      onClick={openAddEditLabelDialog}
                      startIcon={<AddCircleIcon />}
                    >
                      Add Label
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400" paragraph>
                  Call Analysis Report <BetaText />
                </Typography>

                <Typography variant="bodym" className={classes.cardSubtitle}>
                  Get a weekly report with answers to your saved questions from
                  your customer calls
                </Typography>
              </div>

              <Grid item container xs={12} style={{ marginTop: space.MEDIUM }}>
                <FormControlLabel
                  control={
                    <StyledSwitch
                      name={"sentQuestionReport"}
                      checked={questionReport}
                      onChange={toggleQuestionNotifications}
                    />
                  }
                  label="Weekly report"
                  componentsProps={{
                    typography: {
                      variant: "bodym"
                    }
                  }}
                />
              </Grid>
              <Grid style={{ marginTop: space.MEDIUM }}>
                <Typography
                  variant="bodym"
                  paragraph
                  style={{ marginBottom: space.SMALL }}
                >
                  Emails
                </Typography>

                <TagsInput
                  value={questionReportEmails}
                  name="questionReportEmails"
                  size="medium"
                  suggestions={[]}
                  className={classes.width100}
                  addTag={(event: any, newValue: string) => {
                    setQuestionReportEmails([
                      ...questionReportEmails,
                      newValue
                    ]);

                    saveQuestionReportEmails([
                      ...questionReportEmails,
                      newValue
                    ]);
                  }}
                  handleChange={(event: any, newValues: string[]) => {
                    setQuestionReportEmails(newValues);

                    saveQuestionReportEmails(newValues);
                  }}
                />
              </Grid>
              <Grid
                container
                direction="column"
                style={{
                  marginTop: space.L
                }}
              >
                <Typography variant="h300" paragraph>
                  Questions
                </Typography>
              </Grid>
              <Grid container direction="column">
                {questionsLoading && <Loading />}

                {!questionsLoading && questions?.length > 0 && (
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Question</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {questions.map((value: any, index: number) => {
                          return (
                            <TableRow width="100%" key={index + 1}>
                              <TableCell
                                style={{
                                  width: "90%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.question?.question}
                                </Grid>
                              </TableCell>

                              <TableCell
                                style={{
                                  width: "10%"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <div className={classes.actionButton}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => deleteQuestionRow(index)}
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
          </Grid>


          {showAddEditLabelDialog && (
            <StyledDialog
              open={showAddEditLabelDialog}
              title={
                selectedAccountSegmentId
                  ? "Update your label"
                  : "Create your label"
              }
              maxWidth="md"
              body={
                <div style={{ padding: `0 ${space.MEDIUM}` }}>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      autoFocus
                      type="text"
                      size="large"
                      name="label"
                      label="Label Name"
                      fullWidth
                      placeholder="Internal Links"
                      value={accountSegmentLabel}
                      onChange={onAccountSegmentLabelChange}
                      helperText={accountSegmentLabelError}
                      error={Boolean(accountSegmentLabelError)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInputLabel
                      label="Related Content"
                      helperText="If an account reads this content or anything similar, we'll apply the
          above label."
                    />

                    <StyledTab
                      value={selectedTab}
                      tabs={labelTabs}
                      onChange={onTabChange}
                    />
                    <div style={{ marginTop: space.MEDIUM }}>
                      {selectedTab ===
                        labelTabsValue.SELECT_FROM_BLOG_POSTS && (
                        <Autocomplete
                          multiple
                          value={contents}
                          options={posts}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          fullWidth
                          filterOptions={(x) => x}
                          onInputChange={(event, newInputValue) => {
                            setSearchKeyword(newInputValue);
                          }}
                          renderInput={(params) => (
                            <StyledInput
                              fullWidth
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                          renderOption={(props, option) => {
                            return (
                              <Box key={option._id} {...props}>
                                <StyledCheckbox
                                  size={"small"}
                                  checked={getCheckedFlag(option)}
                                  labelStyle={{ marginRight: 10 }}
                                />

                                <Grid container direction="column">
                                  <Typography
                                    variant="bodym"
                                    style={{ marginLeft: space.MEDIUM }}
                                  >
                                    {option?.title}
                                  </Typography>
                                </Grid>
                              </Box>
                            );
                          }}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                              return (
                                <StyledUserChip
                                  key={index}
                                  label={`${option.title}`}
                                  {...getTagProps({ index })}
                                />
                              );
                            })
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setContents(newValue);
                            }
                          }}
                        />
                      )}
                      {selectedTab === labelTabsValue.DEFINE_A_PATH && (
                        <PathSelectionFilter
                          filterList={[{ name: "URL", value: "url" }]}
                          filters={filters}
                          onChange={onFilterChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              }
              successButtonName={selectedAccountSegmentId ? "Update" : "Save"}
              successCallback={saveOrUpdateAccountSegment}
              cancelCallback={closeAddEditLabelDialog}
            />
          )}
        </div>

        <DemoVideoDialog location={location} />
      </Container>
    </div>
  );
}

export default withRouter(withStyles(styles)(AISettings));
