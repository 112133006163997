import { Autocomplete } from "@mui/material";
import React from "react";
import StyledInput from "./StyledInput";
import StyledUserChip from "./StyledUserChip";

type StyledChipInputProps = {
  value: string[];
  onChange: (newChips: string[]) => void;
  placeholder?: string;
  size?: string;
  fullWidth?: boolean;
  limitTags?: number;
};

export default function StyledChipInput({
  onChange,
  value,
  placeholder,
  limitTags,
  fullWidth
}: StyledChipInputProps) {
  const chips = value || [];

  const handleChipChange = (_: any, newChips: string[]) => {
    onChange(newChips);
  };

  const handleDelete = (chipToDelete: string) => () => {
    const newChips = chips.filter((chip) => chip !== chipToDelete);
    onChange(newChips);
  };

  const handleKeyDown = (e: any) => {
    const inputText = e.target.value;
    if (e.ctrlKey && e.key === "c" && !e.shiftKey) {
      if (!inputText && value) {
        e.preventDefault();
        e.defaultMuiPrevented = true;
        navigator.clipboard.writeText(value.join(",")).catch((err) => {});
      }
    } else if (e.ctrlKey && e.key === "v" && !e.shiftKey) {
      if (!inputText) {
        e.preventDefault();
        e.defaultMuiPrevented = true;
        navigator.clipboard
          .readText()
          .then((text) => {
            if (!text) return;
            let textArray = text.split(",");
            handleChipChange(e, [...value, ...textArray]);
          })
          .catch((err) => {});
      }
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={chips}
      onChange={handleChipChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <StyledUserChip
            {...getTagProps({ index })}
            label={option}
            onDelete={handleDelete(option)}
          />
        ))
      }
      renderInput={(params) => (
        <StyledInput
          placeholder={placeholder}
          {...params}
          size="small"
          onKeyDown={handleKeyDown}
        />
      )}
      limitTags={limitTags}
    />
  );
}
