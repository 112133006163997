// @ts-nocheck
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/SlackApi";
import { Typography } from "@mui/material";
import { space } from "../Config/theme";
import StyledCheckbox from "../design/components/StyledCheckbox";

const api = API.create();

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(4),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing(2)
  }
}));

export default function SelectSlackChannel({
  open,
  handleClose,
  publicationId
}: any) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [errorDetails, setError] = React.useState({
    error: false,
    message: ""
  });
  const [channels, setChannels] = React.useState([]);
  const [selectedChannel, setSelectedChannel] = React.useState({
    id: "-1",
    name: "Select"
  });
  const [selectedChannelForAccountAlerts, setSelectedChannelForAccountAlerts] =
    React.useState({
      id: "-1",
      name: "Select"
    });

  const [selectedChannelForKeywordAlerts, setSelectedChannelForKeywordAlerts] =
    React.useState({
      id: "-1",
      name: "Select"
    });

  const [
    salesToSocialNotificationChannel,
    setSalesToSocialNotificationChannel
  ] = React.useState({
    id: "-1",
    name: "Select"
  });

  const [notifySalesToSocialIndividuals, setNotifySalesToSocialIndividuals] =
    React.useState(true);

  const [selectionError, setSelectionError] = React.useState(false);

  const load = () => {
    api.getPublicChannels(publicationId, (res: any) => {
      setLoading(false);
      if (res.status === 200) {
        let defaultOption = {
          id: "-1",
          name: "Select a channel"
        };

        res.data.channels.unshift(defaultOption);
        setChannels(res.data.channels);
        if (res.data.notificationChannel && res.data.notificationChannel.id) {
          setSelectedChannel(res.data.notificationChannel);
        }
        if (
          res.data.accountNotificationChannel &&
          res.data.accountNotificationChannel.id
        ) {
          setSelectedChannelForAccountAlerts(
            res.data.accountNotificationChannel
          );
        }

        if (
          res.data.socialKeywordMonitoringChannel &&
          res.data.socialKeywordMonitoringChannel.id
        ) {
          setSelectedChannelForKeywordAlerts(
            res.data.socialKeywordMonitoringChannel
          );
        }

        if (
          res.data.salesToSocialNotificationChannel &&
          res.data.salesToSocialNotificationChannel.id
        ) {
          setSalesToSocialNotificationChannel(
            res.data.salesToSocialNotificationChannel
          );
        }
        setNotifySalesToSocialIndividuals(
          res.data?.notifySalesToSocialIndividuals
        );
      } else if (res.status === 400) {
        if (res.data.requiresAuthorization) {
          setError({
            error: true,
            message:
              "Please reinstall Letterdrop in your Slack workspace. We need additional permissions to continue."
          });
          return;
        }
        setError({
          error: true,
          message:
            "We couldn't find your Slack channels. Please contact support@letterdrop.com."
        });
      } else {
        setError({
          error: true,
          message:
            "We couldn't find your Slack channels. Please contact support@letterdrop.com."
        });
      }
    });
  };

  useEffect(load, []);

  const saveSelectedChannel = () => {
    api.saveNotificationChannel(
      publicationId,
      !selectedChannel || selectedChannel.id === "-1"
        ? null
        : selectedChannel.id,
      !selectedChannelForAccountAlerts ||
        selectedChannelForAccountAlerts.id === "-1"
        ? null
        : selectedChannelForAccountAlerts.id,
      !salesToSocialNotificationChannel ||
        salesToSocialNotificationChannel?.id === "-1"
        ? null
        : salesToSocialNotificationChannel.id,
      notifySalesToSocialIndividuals,
      !selectedChannelForKeywordAlerts ||
        selectedChannelForKeywordAlerts?.id === "-1"
        ? null
        : selectedChannelForKeywordAlerts.id,
      (res: any) => {
        if (res.status === 200) {
          handleClose();
        } else {
          setError({
            error: true,
            message:
              "We couldn't save your selected channel. Try again or contact support@letterdrop.com."
          });
        }
      }
    );
  };

  return (
    <React.Fragment>
      <StyledDialog
        open={open}
        title=" Select Slack channel"
        body={
          <>
            <span>
              We'll send notifications to this channel for post deadlines and
              approvals. We suggest picking a channel with your marketing team.
            </span>

            {loading && (
              <Skeleton width={"100%"} height={50} style={{ marginTop: 10 }} />
            )}
            {!loading && errorDetails.error && (
              <Alert severity="error" style={{ marginTop: 10 }}>
                {errorDetails.message}
              </Alert>
            )}

            {!loading && !errorDetails.error && (
              <>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  sx={{ marginBottom: space.LARGE }}
                >
                  <Autocomplete
                    id="select-channel"
                    options={channels}
                    value={selectedChannel}
                    onChange={(event, newValue) => {
                      setSelectionError(false);
                      setSelectedChannel(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    renderInput={(params) => (
                      <StyledInput autoFocus {...params} variant="outlined" />
                    )}
                  />
                </FormControl>
                <Typography variant="bodym">
                  Select a channel to receive daily updates on active accounts
                </Typography>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  sx={{ marginBottom: space.LARGE }}
                >
                  <Autocomplete
                    options={channels}
                    value={selectedChannelForAccountAlerts}
                    onChange={(event, newValue) => {
                      setSelectedChannelForAccountAlerts(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    renderInput={(params) => (
                      <StyledInput {...params} variant="outlined" />
                    )}
                  />
                </FormControl>

                <Typography variant="bodym">LinkedIn keyword alerts</Typography>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  sx={{ marginBottom: space.LARGE }}
                >
                  <Autocomplete
                    options={channels}
                    value={selectedChannelForKeywordAlerts}
                    onChange={(event, newValue) => {
                      setSelectedChannelForKeywordAlerts(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    renderInput={(params) => (
                      <StyledInput {...params} variant="outlined" />
                    )}
                  />
                </FormControl>

                <Typography variant="bodym">Social posts from calls</Typography>
                <FormControl className={classes.formControl} fullWidth>
                  <Autocomplete
                    options={channels}
                    value={salesToSocialNotificationChannel}
                    onChange={(event, newValue) => {
                      setSalesToSocialNotificationChannel(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    renderInput={(params) => (
                      <StyledInput {...params} variant="outlined" />
                    )}
                  />
                </FormControl>
                <StyledCheckbox
                  label="Only send to channel, not individuals"
                  checked={!notifySalesToSocialIndividuals}
                  onChange={(event) => {
                    setNotifySalesToSocialIndividuals(!event.target.checked);
                  }}
                />
              </>
            )}

            {selectionError && (
              <Alert
                severity="warning"
                style={{
                  marginTop: 10
                }}
              >
                Select a channel
              </Alert>
            )}
          </>
        }
        successButtonName="Save"
        successCallback={saveSelectedChannel}
        cancelButtonName="Close"
        cancelCallback={handleClose}
      />
    </React.Fragment>
  );
}
