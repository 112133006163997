import * as React from "react";
import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { designColors } from "../../Themes/Colors";
import { FormControlLabel } from "@mui/material";

const colors = {
  primary: designColors.primary[100],
  error: designColors.alert[50]
};

const sizes = {
  small: 16,
  medium: 20,
  large: 24
};

const labelSizes = {
  small: "bodys",
  medium: "bodym",
  large: "bodyl"
};

const styledProps = {
  small: {
    size: 16,
    font: "bodys",
    radius: 4
  },
  medium: {
    size: 20,
    font: "bodym",
    radius: 4
  },
  large: {
    size: 24,
    font: "bodyl",
    radius: 6
  }
};

const CheckboxIcon = styled("span")<{
  fontSize?: keyof typeof styledProps;
  color?: keyof typeof colors;
}>(({ fontSize = "medium", color = "primary" }) => {
  let size = styledProps[fontSize].size;

  let themeColor = colors[color] || colors.primary;
  let boxShadowColor =
    color === "error" ? colors.error : designColors.grayScale[40];

  return {
    borderRadius: styledProps[fontSize].radius,
    width: size,
    height: size,
    boxShadow: `inset 0 0 0 1px ${boxShadowColor}, inset 0 -1px 0 ${boxShadowColor}`,
    backgroundColor: designColors.white,
    backgroundImage: `linear-gradient(180deg,${designColors.white},${designColors.white})`,
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      background: designColors.primary[0],
      boxShadow: `inset 0 0 0 1px ${themeColor}, inset 0 -1px 0 ${themeColor}`
    },
    "input:disabled ~ &": {
      boxShadow: `inset 0 0 0 1px ${designColors.grayScale[40]}, inset 0 -1px 0 ${designColors.grayScale[40]}`,
      background: designColors.grayScale[5]
    }
  };
});

const CheckboxIconChecked = styled(CheckboxIcon)(
  ({ fontSize = "medium", color = "primary" }) => {
    let size = styledProps[fontSize].size;

    let themeColor = colors[color] || colors.primary;

    return {
      backgroundColor: themeColor,
      boxShadow: `inset 0 0 0 2px ${themeColor}, inset 0 0px 0 ${themeColor}`,
      backgroundImage: `linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))`,
      "&:before": {
        display: "block",
        width: size,
        height: size,
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.91006 14.4959L7.7071 12.2929C7.31658 11.9024 6.68342 11.9024 6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071L9.29288 16.7071C9.7168 17.131 10.4159 17.0892 10.7863 16.6178L17.7863 8.61786C18.1275 8.18359 18.0521 7.55494 17.6178 7.21372C17.1835 6.87251 16.5549 6.94795 16.2136 7.38222L9.91006 14.4959Z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""'
      },
      "input:disabled ~ &:before": {
        borderRadius: styledProps[fontSize].radius,
        display: "block",
        width: size,
        height: size,
        backgroundColor: designColors.grayScale[60],
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.91006 14.4959L7.7071 12.2929C7.31658 11.9024 6.68342 11.9024 6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071L9.29288 16.7071C9.7168 17.131 10.4159 17.0892 10.7863 16.6178L17.7863 8.61786C18.1275 8.18359 18.0521 7.55494 17.6178 7.21372C17.1835 6.87251 16.5549 6.94795 16.2136 7.38222L9.91006 14.4959Z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""'
      },
      "input:hover ~ &": {
        backgroundColor: themeColor
      }
    };
  }
);

const CheckboxIndeterminateIcon = styled(CheckboxIcon)<{
  indeterminate: boolean;
}>(({ fontSize = "medium", color = "primary" }) => {
  let size = styledProps[fontSize].size;

  let themeColor = colors[color] || colors.primary;

  return {
    backgroundColor: themeColor,
    boxShadow: `inset 0 0 0 2px ${themeColor}, inset 0 0px 0 ${themeColor}`,
    backgroundImage: `linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))`,
    "&:before": {
      display: "block",
      width: size,
      height: size,
      backgroundRepeat: "no-repeat",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Crect x='6' y='11' width='12' height='2' rx='1' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:disabled ~ &:before": {
      borderRadius: styledProps[fontSize].radius,
      display: "block",
      width: size,
      height: size,
      backgroundColor: designColors.grayScale[60],
      backgroundRepeat: "no-repeat",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Crect x='6' y='11' width='12' height='2' rx='1' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: themeColor
    }
  };
});

export default function StyledCheckbox(
  props: Omit<CheckboxProps, "icon" | "checkedIcon" | "indeterminateIcon"> & {
    labelStyle?: any;
    label?: string;
    size?: keyof typeof sizes;
    fontsize?: keyof typeof styledProps;
    color?: keyof typeof colors;
  }
) {
  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          typography: labelSizes[props.size || "medium"] || labelSizes.medium
        }
      }}
      control={
        <Checkbox
          icon={<CheckboxIcon fontSize={props.fontsize} color={props.color} />}
          checkedIcon={
            <CheckboxIconChecked
              fontSize={props.fontsize}
              color={props.color}
            />
          }
          indeterminateIcon={
            <CheckboxIndeterminateIcon
              fontSize={props.fontsize}
              color={props.color}
              indeterminate={true}
            />
          }
          {...props}
          sx={{
            "&.MuiCheckbox-root": {
              padding: 0,
              margin: "10px"
            }
          }}
        />
      }
      style={props.labelStyle || {}}
      label={props.label || ""}
    />
  );
}
