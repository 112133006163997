import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { notify } from "../Components/CustomNotifications";
import PathSelectionFilter from "../Components/PathSelectionFilter";
import { shadow, space } from "../Config/theme";
import SearchBar from "../design/components/SearchBar";
import StyledAutocomplete from "../design/components/StyledAutocomplete";
import StyledButton from "../design/components/StyledButton";
import StyledPagination from "../design/components/StyledPagination";
import StyledTable from "../design/components/StyledTable";
import { ReactComponent as FilterIcon } from "../Images/icon20/filterprojects.svg";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import { uploadFile, uploadMp3Audio, uploadMp4Video } from "../Utils/Storage";
import { Publication } from "../Utils/Types";
import { AUTOCOMPLETE_ALL_OPTION_TEXT } from "./LinkedInShare";
import styles from "./styles/KnowledgeBaseSettingsStyles";

const api = API.create();

const ContentIndexTypes = [
  {
    userId: "url",
    name: "Web Page"
  },
  {
    userId: "pdf",
    name: "PDF"
  },
  {
    userId: "video",
    name: "Video"
  },
  {
    userId: "audio",
    name: "Audio"
  }
];

const allowedExtensions = /(\.pdf|\.mp3|\.mp4)$/i; // allow pdf, mp3 and mp4 files

type IndexedPage = {
  name: string;
  type: string;
};

type KnowledgeBaseSalesEnablementProps = {
  publicationId: string;
  publication: Publication | null;
  classes: Record<keyof ReturnType<typeof styles>, string>;
};

const KnowledgeBaseSalesEnablement: React.FC<
  KnowledgeBaseSalesEnablementProps
> = ({ publicationId, publication, classes }) => {
  const [indexedPages, setIndexedPages] = React.useState<IndexedPage[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [indexing, setIndexing] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);

  const [urlSearched, setUrlSearched] = React.useState("");
  const [showFilter, setShowFilter] = React.useState(false);
  const [filterButtonElement, setFilterButtonElement] =
    React.useState<HTMLElement | null>(null);
  const [selectedTypes, setSelectedTypes] = React.useState(ContentIndexTypes);
  const [isAllTypeFilterChecked, setIsAllTypeFilterChecked] =
    React.useState(true);

  const deleteIndexedPage = (index: number) => {
    let newIndexedPages = [...indexedPages];
    newIndexedPages.splice(index, 1);
    setIndexedPages(newIndexedPages);
    api.deleteIndexedPage(publicationId, indexedPages[index]?.name, (res) => {
      if (res.status === 200) {
        notify.show("Page deleted successfully", "success");
        getContentIndexed();
      } else {
        notify.show("Error deleting page", "error");
      }
    });
  };

  const getTableBody = () => {
    return indexedPages.map((indexedPage: IndexedPage, index: number) => {
      let data = [
        { value: indexedPage.name, style: { width: "70%" } },
        { value: indexedPage.type, style: { width: "10%" } },
        {
          value: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
              }}
            >
              <div style={{ paddingRight: space.LARGE }}>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => deleteIndexedPage(index)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ),
          style: { width: "20%" }
        }
      ];
      return {
        id: index,
        data
      };
    });
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const onSearchTextChange = (value: string) => {
    setUrlSearched(value);
  };

  const toggleFilterOptions = (
    element: HTMLElement | null,
    showFilterOptions: boolean
  ) => {
    setFilterButtonElement(element);
    setShowFilter(showFilterOptions);
  };

  const onTypeFiltersChange = (
    e: React.BaseSyntheticEvent,
    values: typeof ContentIndexTypes
  ) => {
    const allFlagSelected = values.find(
      (status) => status.name === AUTOCOMPLETE_ALL_OPTION_TEXT
    );

    if (allFlagSelected) {
      const populateAllAccounts = !isAllTypeFilterChecked;
      setSelectedTypes(populateAllAccounts ? ContentIndexTypes : []);
      setIsAllTypeFilterChecked(populateAllAccounts);
    } else {
      setSelectedTypes(values);
      setIsAllTypeFilterChecked(values.length === ContentIndexTypes.length);
    }
  };

  const isTypeSelected = (option: (typeof ContentIndexTypes)[0]) => {
    if (isAllTypeFilterChecked) {
      return true;
    }

    let exists = selectedTypes.find(
      (account) => account.userId === option.userId
    );

    return exists ? true : false;
  };

  const openFileUpload = () => {
    if (uploading) {
      notify.show("Already a file is uploading", "error");
      return;
    }
    fileUploadRef?.current?.click();
  };

  const contentIndexing = () => {
    if (indexing) {
      notify.show("Already indexing in progress", "error");
      return;
    }
    setIndexing(true);
    api.contentIndex(publicationId, (res) => {
      setIndexing(false);
      notify.show("Started indexing your URLs", "success");
    });
  };

  const calculateFileSize = (fileObject: File) => {
    return fileObject.size / (1024 * 1024);
  };

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) return;

    setUploading(true);

    let fileObject = event.target.files[0];

    if (!allowedExtensions.exec(fileObject.name)) {
      notify.show("Invalid file type", "error");
      return;
    }

    if (calculateFileSize(fileObject) > 50) {
      notify.show("Upload file size limit is 50 mb", "error");
      return;
    }

    uploadFileForIndexing(fileObject);
    event.target.value = "";
  };

  const uploadFileForIndexing = async (fileObject: File) => {
    let response: any, url: any, fileType: any;
    if (fileObject.name?.includes(".pdf")) {
      response = await uploadFile(fileObject, publication?.domain);
      fileType = "pdf";
    } else if (fileObject.name?.includes(".mp4")) {
      response = await uploadMp4Video(fileObject, publication?.domain);
      fileType = "video";
    } else if (fileObject.name?.includes(".mp3")) {
      response = await uploadMp3Audio(fileObject, publication?.domain);
      fileType = "audio";
    }
    url = response;

    api.importFileForIndexing(
      fileObject,
      url,
      fileObject.name,
      publicationId,
      fileType,
      (res) => {
        if (res.status === 200) {
          notify.show(res.data.message, res.data.success ? "success" : "error");
        } else {
          notify.show("Oops, something went wrong.", "error");
        }
        setUploading(false);
      }
    );
  };

  const onSearch = () => {
    setPage(0);
    getContentIndexed();
  };

  const cancelSearch = () => {
    setUrlSearched("");
    getContentIndexed(true);
  };

  const getContentIndexed = (cancelSearch: boolean = false) => {
    api.getContentIndexed(
      publicationId,
      selectedTypes,
      rowsPerPage,
      urlSearched ? 0 : page * rowsPerPage,
      cancelSearch ? "" : urlSearched,
      (res) => {
        if (res.status === 200) {
          setIndexedPages(res.data.indexedPages);
          setTotalCount(res.data.totalCount);
        } else {
          notify.show("Error getting indexed pages", "error");
        }
      }
    );
  };

  useEffect(() => {
    getContentIndexed();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, selectedTypes]);

  if (!publication) return <></>;

  return (
    <>
      <Grid item xs={12} style={{ marginTop: space.LARGE }}>
        <hr color={colors.horizontalRule} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: space.LARGE
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Typography variant="h400" paragraph>
              Sales Enablement Knowledge Base
            </Typography>
            <StyledButton
              variant="primary"
              size="medium"
              onClick={contentIndexing}
            >
              Index Content
            </StyledButton>
          </Grid>
          <Typography variant="bodym" paragraph>
            These are the pages and files that our AI will index and use for
            content suggestions to sales.
          </Typography>

          <Grid container direction="column">
            <PathSelectionFilter
              filterList={[{ name: "URL", value: "url" }]}
              filters={publication?.indexPathConditions?.map((condition) => ({
                ...condition,
                filter: "url",
                pathErrorMessage: ""
              }))}
              onChange={(filters) => {
                api.saveIndexPathConditions(publicationId, filters, (res) => {
                  if (res.status !== 200) {
                    notify.show("Error saving content path", "error");
                  }
                });
              }}
            />
          </Grid>

          <Grid
            container
            justifyContent="center"
            style={{ paddingTop: space.MEDIUM }}
          >
            <SearchBar
              onChange={onSearchTextChange}
              value={urlSearched}
              onRequestSearch={onSearch}
              onCancelSearch={cancelSearch}
              placeholder={"Search"}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <StyledButton
              variant="textprimary"
              size="medium"
              style={{ marginRight: space.MEDIUM }}
              onClick={openFileUpload}
            >
              Upload
            </StyledButton>
            {uploading && (
              <CircularProgress
                size={20}
                color="inherit"
                style={{ marginRight: 10 }}
              />
            )}
            <input
              id="myInput"
              type="file"
              ref={fileUploadRef}
              style={{ display: "none" }}
              accept=".pdf,.mp3,.mp4"
              onChange={onFileChange}
            />

            <IconButton
              onClick={(event) => {
                toggleFilterOptions(event.currentTarget, true);
              }}
              size="large"
            >
              <FilterIcon />
            </IconButton>
          </Grid>

          <Grid container direction="column">
            <StyledTable
              tableWidth="100%"
              tableContainerProps={{
                style: { marginTop: space.SMALL }
              }}
              headerArray={[
                {
                  value: "Name",
                  headerAlignment: "left",
                  style: { width: "70%" }
                },
                {
                  value: "Type",
                  headerAlignment: "left",
                  style: { width: "10%" }
                },
                {
                  value: "",
                  headerAlignment: "center",
                  style: { width: "20%" }
                }
              ]}
              bodyArray={getTableBody()}
            />
            {!Boolean(indexedPages?.length) && (
              <div style={{ textAlign: "center" }}>
                <Typography variant="bodym" paragraph>
                  No URLs indexed for this workspace.
                </Typography>
              </div>
            )}
            <div>
              <StyledPagination
                rowsPerPageOptions={[10, 20, 50]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </div>
          </Grid>
        </Box>
      </Grid>

      {showFilter && (
        <ClickAwayListener
          onClickAway={() => {
            toggleFilterOptions(null, false);
          }}
        >
          <Popper
            open={showFilter}
            placement="right-start"
            style={{ position: "absolute", zIndex: 999, width: "350px" }}
            anchorEl={filterButtonElement}
          >
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                padding: space.SMALL,
                boxShadow: shadow.MEDIUM
              }}
            >
              <Typography
                variant="bodyl"
                style={{
                  marginBottom: space.XS
                }}
              >
                Type
              </Typography>
              <StyledAutocomplete
                value={selectedTypes}
                onChange={onTypeFiltersChange}
                options={ContentIndexTypes}
                isCheckboxSelected={isTypeSelected}
                showAvatar={false}
              />
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default withRouter(withStyles(styles)(KnowledgeBaseSalesEnablement));
