import apisauce from "apisauce";
import { AxiosResponse } from "axios";
import firebase from "firebase/app";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;

const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": SERVER_URL,
      "Access-Control-Allow-Methods": "GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    timeout: 180000
  });

  const createApiCall = (endpoint: any, params: any, callback: any) => {
    firebase
      .auth()
      .currentUser?.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/outreach/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const connect = (publicationId: any) => {
    firebase
      .auth()
      .currentUser?.getIdToken()
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/outreach/connect?idToken=" +
            idToken +
            "&publicationId=" +
            publicationId,
          "_self"
        );
      });
  };

  return {
    connect
  };
};

export default {
  create
};
