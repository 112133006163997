import { Autocomplete, Avatar, Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { AxiosResponse } from "axios";
import Papa from "papaparse";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AvatarSize, space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import Api from "../Services/Api";
import {
  DEFAULT_GEOGRAPHIES,
  DEFAULT_SENIORITY,
  Publication
} from "../Utils/Types";
import { notify } from "./CustomNotifications";
import Loading from "./Loading";
import styles from "./styles/AccountMonitoringStyle";
import Uploader from "./Uploader";
import { handleSingular } from "../Utils/UserUtils";
import BetaText from "./BetaText";

const api = Api.create();

type AccountMonitoringProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
  publication: Publication | null;
} & RouteComponentProps<{ id: string }>;

type AccountMonitoringState = {
  loading: boolean;
  saveInProgress: boolean;
  showUploadCSVDialog: boolean;
  companies: Companies;
  roles: string[];
  accounts: LinkedinAccount[];
  selectedAccounts: LinkedinAccount[];
  selectedFileName: string;
  seniorities: string[];
  geographies: string[];
};

export type Companies = {
  [key: string]: string[];
};

type LinkedinAccount = {
  connected: boolean;
  name: string;
  userId: string;
  vanityName: string;
  profilePicture: string;
  email: string;
  accountEmails: string;
};

class AccountMonitoring extends React.Component<
  AccountMonitoringProps,
  AccountMonitoringState
> {
  constructor(props: AccountMonitoringProps) {
    super(props);
    this.state = {
      loading: true,
      saveInProgress: false,
      showUploadCSVDialog: false,
      accounts: [],
      selectedAccounts: [],
      companies: {},
      roles: [],
      selectedFileName: "",
      seniorities: [],
      geographies: []
    };
  }

  componentDidMount(): void {
    this.getConnectedLinkedinAccounts();
  }

  getConnectedLinkedinAccounts = () => {
    let { publication } = this.props;

    api.getConnectedLinkedinAccounts(
      publication?._id || "",
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            accounts:
              res.data?.filter(
                (account: LinkedinAccount) =>
                  account.connected && account.vanityName
              ) || []
          });
        } else {
          notify.show(
            res.status === 400 ? res.data : "Oops, something went wrong.",
            "error"
          );
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  onFileChange = async (file: any) => {
    let { accounts } = this.state;

    const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 2MB Limit for thumbnail image
    if (file.size > FILE_SIZE_LIMIT) {
      notify.show("Upload a CSV file that's smaller than 10 MB", "error");
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        if (!results.data?.length) {
          notify.show("CSV file is empty", "error");
          return;
        }

        let data = results.data;

        if (
          !data[0].hasOwnProperty("company") ||
          !data[0].hasOwnProperty("role") ||
          !data[0].hasOwnProperty("email")
        ) {
          notify.show(
            `Missing required column ${
              !data[0].hasOwnProperty("company")
                ? "company"
                : data[0].hasOwnProperty("role")
                ? "role"
                : "email"
            }`,
            "error"
          );
          return;
        }

        if (data.length > 1000) {
          data.length = 1000;
        }

        let companies: Companies = {};
        let roles: string[] = [];
        let seniorities = DEFAULT_SENIORITY;
        let geographies = DEFAULT_GEOGRAPHIES;

        if (data[0].hasOwnProperty("seniority")) {
          seniorities = [];
        }

        if (data[0].hasOwnProperty("geography")) {
          geographies = [];
        }

        for (let { company, role, email, seniority, geography } of data) {
          if (role?.trim()) {
            roles.push(role);
          }

          if (seniority?.trim()) {
            seniorities.push(seniority);
          }

          if (geography?.trim()) {
            geographies.push(geography);
          }

          if (!company?.trim()) {
            continue;
          }

          if (!email?.trim()) {
            notify.show(`Missing email for company ${company}`, "error");
            return;
          }

          companies[email] = companies[email] || [];
          companies[email].push(company);
        }

        if (!this.getCompaniesCount(companies)) {
          notify.show("No companies found in the CSV file", "error");
          return;
        }

        if (!roles.length) {
          notify.show("No roles found in the CSV file", "error");
          return;
        }

        let connectedAccountsEmails =
          accounts
            ?.map((account) => {
              return account.accountEmails || [];
            })
            ?.flat() || [];

        let invalidEmails = [];

        for (const email of Object.keys(companies)) {
          if (!connectedAccountsEmails.includes(email)) {
            invalidEmails.push(email);
          }
        }

        if (invalidEmails.length) {
          notify.show(
            `The following emails are not connected to any LinkedIn account: ${invalidEmails.join(
              ", "
            )}`,
            "error"
          );
          return;
        }

        this.setState({
          companies,
          roles,
          seniorities,
          geographies,
          selectedFileName: file.name
        });
      },
      error: (error) => {
        console.log(error);
      }
    });
  };

  closeUploadCSVDialog = () => {
    this.setState({
      showUploadCSVDialog: false,
      companies: {},
      roles: [],
      selectedFileName: ""
    });
  };

  saveAccountsForMonitoring = () => {
    let {
      selectedAccounts,
      companies,
      roles,
      saveInProgress,
      seniorities,
      geographies
    } = this.state;
    let { publication } = this.props;

    if (saveInProgress) {
      return;
    }

    if (!selectedAccounts.length) {
      notify.show("Select at least one LinkedIn account", "error");
      return;
    }

    if (!this.getCompaniesCount(companies)) {
      notify.show("No companies found in the CSV file", "error");
      return;
    }

    if (!roles.length) {
      notify.show("No roles found in the CSV file", "error");
      return;
    }

    this.setState({ saveInProgress: true }, () => {
      api.saveAccountsForMonitoring(
        publication?._id || "",
        selectedAccounts?.map((account) => account.vanityName) || [],
        companies,
        roles,
        seniorities,
        geographies,
        (res: AxiosResponse) => {
          if (res.status === 200) {
            this.setState({
              saveInProgress: false,
              showUploadCSVDialog: false,
              companies: {},
              roles: [],
              selectedFileName: ""
            });
            notify.show("Accounts saved successfully", "success");
          } else {
            notify.show(
              res.status === 400 ? res.data : "Oops, something went wrong.",
              "error"
            );
            this.setState({
              saveInProgress: false
            });
          }
        }
      );
    });
  };

  getCompaniesCount = (companies: Companies) => {
    let companiesCount = 0;

    Object.keys(companies || {}).forEach((key) => {
      companiesCount += companies[key]?.length || 0;
    });

    return companiesCount;
  };

  getCompaniesAndRolesLabel = () => {
    let { selectedFileName, roles, companies, seniorities, geographies } =
      this.state;

    let companiesCount = this.getCompaniesCount(companies) || 0;

    return (
      <Typography
        variant="bodym"
        paragraph
        style={{ marginBottom: space.SMALL, margin: "0 10px" }}
      >
        <b>{selectedFileName}</b> - <b>{companiesCount}</b>{" "}
        {companiesCount === 1 ? "company" : "companies"}, <b>{roles.length}</b>{" "}
        role{handleSingular(roles.length)}, <b>{seniorities.length}</b>{" "}
        {seniorities?.length === 1 ? "seniority" : "seniorities"},{" "}
        <b>{geographies.length}</b>{" "}
        {geographies?.length === 1 ? "geography" : "geographies"}
      </Typography>
    );
  };

  render() {
    let { classes } = this.props;
    let {
      loading,
      showUploadCSVDialog,
      selectedAccounts,
      accounts,
      selectedFileName,
      saveInProgress
    } = this.state;

    if (loading) {
      return <Loading marginTop={space.L} />;
    }

    return (
      <Box className={classes.container}>
        <Typography variant="h400" paragraph>
          Account Monitoring
          <BetaText />
        </Typography>
        <Typography variant="bodym" paragraph>
          Upload a CSV file to monitor your accounts. The file should include
          the following columns: company, email, and role. {<br />}The file may
          contain up to 1,000 companies and 20 roles, with a maximum file size
          of 10 MB.
        </Typography>
        <div>
          <StyledButton
            variant="primary"
            size="medium"
            onClick={() => {
              this.setState({
                showUploadCSVDialog: true
              });
            }}
          >
            Upload
          </StyledButton>
        </div>

        {showUploadCSVDialog && (
          <StyledDialog
            open={showUploadCSVDialog}
            title="Upload accounts CSV file"
            body={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Typography
                  variant="bodym"
                  paragraph
                  style={{ marginBottom: space.SMALL }}
                >
                  Upload CSV
                </Typography>

                <Uploader csvOnly={true} onImageUpload={this.onFileChange} />
                {selectedFileName && this.getCompaniesAndRolesLabel()}

                <Typography
                  variant="bodym"
                  paragraph
                  style={{
                    marginBottom: space.SMALL,
                    marginTop: space.LARGE
                  }}
                >
                  Select LinkedIn accounts with SalesNav access
                </Typography>

                <Autocomplete
                  options={accounts}
                  value={selectedAccounts}
                  multiple
                  fullWidth
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(
                    option: LinkedinAccount,
                    value: LinkedinAccount
                  ) => {
                    return option.userId === value.userId;
                  }}
                  renderInput={(params) => (
                    <StyledInput {...params} variant="outlined" size="medium" />
                  )}
                  onChange={(event: unknown, newValues: LinkedinAccount[]) => {
                    this.setState({ selectedAccounts: newValues });
                  }}
                  renderOption={(props, option: LinkedinAccount, index) => {
                    return (
                      <Box
                        component="li"
                        key={option.userId}
                        {...props}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Avatar
                          sx={{ ...AvatarSize.sm, display: "flex" }}
                          src={option?.profilePicture}
                        />
                        <Typography
                          variant="bodym"
                          style={{ marginLeft: space.SMALL }}
                        >
                          {option.name}
                        </Typography>
                      </Box>
                    );
                  }}
                />
              </div>
            }
            successButtonName="Save"
            cancelButtonName="Cancel"
            successCallback={this.saveAccountsForMonitoring}
            closeCallback={() => {
              this.closeUploadCSVDialog();
            }}
            showSuccessButtonLoading={saveInProgress || false}
            cancelCallback={() => {
              this.closeUploadCSVDialog();
            }}
          />
        )}
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(AccountMonitoring));
