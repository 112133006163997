// @ts-nocheck
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { ReactComponent as ChevronDownIcon } from "../../Images/icon24/chevron_down.svg";
import { designColors } from "../../Themes/Colors";
import { forwardRef } from "react";
import { Typography } from "@mui/material";
import { labelSize, space } from "../../Config/theme";

const inputHeight = {
  small: "32px",
  medium: "40px",
  large: "48px",
  xl: "56px",
  xxl: "64px"
};

const StyledSelect = forwardRef(function (
  {
    children,
    label,
    size = "medium",
    flexAlign = false,
    ...other
  }: SelectProps & { flexAlign?: boolean },
  ref
) {
  return (
    <>
      {label && (
        <Typography
          paragraph
          style={{ marginBottom: space.XS }}
          variant={labelSize[size]}
        >
          {label}
        </Typography>
      )}

      <Select
        ref={ref}
        IconComponent={ChevronDownIcon}
        sx={{
          typography: "bodym",
          "&.MuiInputBase-root": {
            borderRadius: "6px",

            height: inputHeight[size] || inputHeight.medium,
            "&:hover fieldset": {
              borderColor: designColors.primary[100]
            },
            "&.Mui-focused fieldset": {
              borderColor: designColors.primary[100],
              borderWidth: "1px"
            },
            " .MuiInputBase-input": {
              ...(flexAlign && {
                display: "flex",
                alignItems: "center"
              })
            }
          },
          "& .MuiSelect-icon": {
            top: "auto"
          }
        }}
        {...other}
      >
        {children}
      </Select>
    </>
  );
});

export default StyledSelect;

export function StyledMenuItem({ children, ...other }: any) {
  return (
    <MenuItem
      sx={{
        typography: "bodym",
        "&.MuiMenuItem-root": {
          padding: "12px"
        }
      }}
      {...other}
    >
      {children}
    </MenuItem>
  );
}
