import { Theme } from "@mui/material";
import { createStyles } from "@mui/styles";
import colors from "../../Themes/Colors";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      color: colors.fontPrimary
      // backgroundColor: colors.darkerBackgroundColor
    },
    innerContainer: {
      paddingBottom: 80,
      color: colors.fontPrimary
      // backgroundColor: colors.darkerBackgroundColor
    }
  });
export default styles;
