import { Theme } from "@mui/material";
import { createStyles } from "@mui/styles";
import { space } from "../../Config/theme";
import colors from "../../Themes/Colors";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      color: colors.fontPrimary
      // backgroundColor: colors.darkerBackgroundColor
    },

    innerContainer: {
      paddingBottom: 80,
      color: colors.fontPrimary
      // backgroundColor: colors.darkerBackgroundColor
    },

    gridContainer: {
      height: "100vh",
      marginLeft: 0,
      paddingLeft: 0,
      marginRight: 0,
      paddingRight: 0
    },

    headerStyles: {
      height: "10%",
      [theme.breakpoints.up("xs")]: {
        padding: 10,
        paddingLeft: 25,
        marginTop: 10
      },

      [theme.breakpoints.down("md")]: {
        textAlign: "center"
      }
    },

    pageTitle: {
      color: colors.fontPrimary
    },

    componentHolder: {
      [theme.breakpoints.down("lg")]: {
        height: "100vh"
      },
      [theme.breakpoints.up("sm")]: {
        height: "100vh"
        // overflow: "auto"
      }
    },

    paper: {
      backgroundColor: colors.card,
      borderRadius: 10,
      boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
      padding: 20
    },

    width40: {
      width: "40%"
    },

    amountLabel: {
      marginTop: 15,
      marginRight: 15
    },

    toggleLabel: {
      marginTop: 6,
      marginRight: 12
    },

    marginTop10: {
      marginTop: 10
    },

    toggleBlock: {
      marginTop: 20,
      display: "inline-flex"
    },

    height100: {
      height: "90%"
    },

    marginTop20: {
      marginTop: 20
    },

    margin10: {
      margin: 10
    },

    marginTop12: {
      marginTop: 12
    },

    divider: {
      margin: "30px 0px"
    },

    imageContainer: {
      display: "inline-flex",
      position: "relative",
      marginTop: space.MEDIUM,
      paddingRight: space.MEDIUM,
      width: 200,
      height: 100
    },

    companyDetails: {
      paddingTop: space.XS
    },

    saveCompanyDetailsButton: {
      paddingTop: space.XS,
      textAlign: "end"
    }
  });
export default styles;
